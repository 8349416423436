.footer {
  background-color: @red;
  color: white;
  padding-top: 5rem;
  padding-bottom: 4rem;
  transform: translateZ(0) scale(1,1);
  font-size: 16px;
  .rich-text-wrap {
    font-size: 16px;
  }
  .container {
    position: relative;

  }
  .lang-list {
    .link-colored(white, none);
    @media (min-width: 768px) {
      position: absolute;
      right: 15px;
      bottom: 0;

    }
  }
}
