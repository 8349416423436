
.brand-tile {
  background: url(../img/brand_bg.jpg) no-repeat center;
  background-size: cover;
  border-radius: 5px;
  overflow: hidden;

  min-height: 400px;
  position: relative;
  box-shadow: 6.217px 5.035px 40px 0px rgba(224, 210, 162, 0.75);
  @media (min-width: 768px) {
    height: 530px;

  }

  .title {
    position: absolute;
    left: 4rem;
    bottom: 4rem;
    font-size: 6rem;
    text-transform: uppercase;
    font-weight: 300;
    line-height: 1;
  }
}
.slick-slider {
  .brand-tile {
    box-shadow: none;
  }
}

.brand-slider {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -25px;
  .slide {
    padding-left: 15px;
    padding-right: 15px;
  }

  .slick-list {
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;
  }
}


