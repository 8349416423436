/*!
 * belogoie - Вёрстка и админка сайта Белогорье
 * @version v1.0.0
 * @author Adorazel
 * @link https://adorazel.online
 * @license MIT
 */






html {
  font-family: sans-serif;
  
  line-height: 1.15;
  
  -ms-text-size-adjust: 100%;
  
  -webkit-text-size-adjust: 100%;
  
}


body {
  margin: 0;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}


figcaption,
figure,
main {
  
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  
  height: 0;
  
  overflow: visible;
  
}

pre {
  font-family: monospace, monospace;
  
  font-size: 1em;
  
}


a {
  background-color: transparent;
  
  -webkit-text-decoration-skip: objects;
  
  cursor: pointer;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  
  text-decoration: underline;
  
  text-decoration: underline dotted;
  
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  
  font-size: 1em;
  
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}


audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}


button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  
  font-size: 100%;
  
  line-height: 1.15;
  
  margin: 0;
  
}

button,
input {
  
  overflow: visible;
}

button,
select {
  
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  
  color: inherit;
  
  display: table;
  
  max-width: 100%;
  
  padding: 0;
  
  white-space: normal;
  
}

progress {
  display: inline-block;
  
  vertical-align: baseline;
  
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  
  padding: 0;
  
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  
  outline-offset: -2px;
  
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  
  font: inherit;
  
}


details,
menu {
  display: block;
}

summary {
  display: list-item;
}


canvas {
  display: inline-block;
}

template {
  display: none;
}


[hidden] {
  display: none!important;
}
.row.narrow-row {
  margin-left: -7px;
  margin-right: -7px;
  width: calc(100% + 14px);
}
.row.narrow-row > * {
  padding-left: 7px;
  padding-right: 7px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .col-md-20 {
    width: 20%;
  }
}
@media (min-width: 1200px) {
  .col-lg-20 {
    width: 20%;
  }
}
.flex-row {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
}
.flex-row > * {
  float: none;
}
.flex-row .flex-item {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 100%;
}
.flex-row.narrow-row {
  margin-left: -7px;
  margin-right: -7px;
  width: calc(100% + 14px);
}
.flex-row.narrow-row > * {
  padding-left: 7px;
  padding-right: 7px;
}
.flex-wrap {
  flex-wrap: wrap;
}
@media (min-width: 1200px) {
  .flex-wrap-lg {
    flex-wrap: wrap;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .flex-wrap-md {
    flex-wrap: wrap;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .flex-wrap-sm {
    flex-wrap: wrap;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .flex-wrap-xs {
    flex-wrap: wrap;
  }
}
.jcc {
  justify-content: center;
}
.jsb {
  justify-content: space-between;
}
.jcs {
  justify-content: flex-start;
}
.aic {
  align-items: center;
}
.ait {
  align-items: flex-start;
}
.aib {
  align-items: flex-end;
}
.ais {
  align-items: stretch;
}
.jcsb {
  justify-content: space-between;
}
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.grid {
  margin-left: -15px;
  margin-right: -15px;
}
.grid .grid-item {
  padding-left: 15px;
  padding-right: 15px;
}
.img-wrap {
  display: block;
  position: relative;
  overflow: hidden;
  text-align: center;
}
.img-wrap img {
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle;
  pointer-events: none;
}
.img-wrap.text-right {
  text-align: right;
}
.img-wrap.text-left {
  text-align: left;
}
.captcha-wrap img {
  height: 52px;
  width: 100%;
}
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  position: relative;
  pointer-events: none;
}
ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
}
ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}
.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-BoldCnIt'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCnIt.eot?#iefix') format('embedded-opentype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCnIt.woff2') format('woff2'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCnIt.woff') format('woff'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCnIt.ttf') format('truetype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCnIt.svg#AkzidenzGroteskPro-BoldCnIt') format('svg');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-MdCn'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCn.eot?#iefix') format('embedded-opentype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCn.woff2') format('woff2'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCn.woff') format('woff'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCn.ttf') format('truetype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCn.svg#AkzidenzGroteskPro-MdCn') format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-XBdCn'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCn.eot?#iefix') format('embedded-opentype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCn.woff2') format('woff2'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCn.woff') format('woff'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCn.ttf') format('truetype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCn.svg#AkzidenzGroteskPro-XBdCn') format('svg');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-XBdCnIt'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCnIt.eot?#iefix') format('embedded-opentype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCnIt.woff2') format('woff2'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCnIt.woff') format('woff'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCnIt.ttf') format('truetype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCnIt.svg#AkzidenzGroteskPro-XBdCnIt') format('svg');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-BoldCn'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCn.eot?#iefix') format('embedded-opentype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCn.woff2') format('woff2'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCn.woff') format('woff'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCn.ttf') format('truetype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCn.svg#AkzidenzGroteskPro-BoldCn') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-LightCnItalic'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCnItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCnItalic.woff2') format('woff2'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCnItalic.woff') format('woff'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCnItalic.ttf') format('truetype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCnItalic.svg#AkzidenzGroteskPro-LightCnItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-LightCn'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCn.eot?#iefix') format('embedded-opentype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCn.woff2') format('woff2'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCn.woff') format('woff'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCn.ttf') format('truetype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCn.svg#AkzidenzGroteskPro-LightCn') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-Cn'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Cn.eot?#iefix') format('embedded-opentype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Cn.woff2') format('woff2'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Cn.woff') format('woff'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Cn.ttf') format('truetype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Cn.svg#AkzidenzGroteskPro-Cn') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-MdCnIt'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCnIt.eot?#iefix') format('embedded-opentype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCnIt.woff2') format('woff2'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCnIt.woff') format('woff'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCnIt.ttf') format('truetype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCnIt.svg#AkzidenzGroteskPro-MdCnIt') format('svg');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-CnIt'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-CnIt.eot?#iefix') format('embedded-opentype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-CnIt.woff2') format('woff2'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-CnIt.woff') format('woff'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-CnIt.ttf') format('truetype'), url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-CnIt.svg#AkzidenzGroteskPro-CnIt') format('svg');
  font-weight: normal;
  font-style: italic;
}
body {
  font-family: 'Akzidenz-Grotesk Pro Cnd', Arial, sans-serif;
  line-height: 1.42857143;
  font-size: 2rem;
  font-weight: 300;
  color: #0f0f0f;
}
body * {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input,
optgroup,
select,
textarea {
  font-family: 'Akzidenz-Grotesk Pro Cnd', Arial, sans-serif;
}
a {
  color: #c32428;
  text-decoration: underline;
}
a:hover {
  color: #ad2024;
  text-decoration: none;
}
a:focus {
  color: #c32428;
  text-decoration: underline;
  outline: none;
}
a:active,
a:active:focus {
  color: #981c1f;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #777;
}
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
h1 small,
.h1 small,
h2 small,
.h2 small,
h3 small,
.h3 small,
h1 .small,
.h1 .small,
h2 .small,
.h2 .small,
h3 .small,
.h3 .small {
  font-size: 65%;
}
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
h4 small,
.h4 small,
h5 small,
.h5 small,
h6 small,
.h6 small,
h4 .small,
.h4 .small,
h5 .small,
.h5 .small,
h6 .small,
.h6 .small {
  font-size: 75%;
}
h1,
.h1 {
  font-size: 3.6rem;
}
h2,
.h2 {
  font-size: 3rem;
}
h3,
.h3 {
  font-size: 2.4rem;
}
h4,
.h4 {
  font-size: 1.8rem;
}
h5,
.h5 {
  font-size: 1.4rem;
}
h6,
.h6 {
  font-size: 1.2rem;
}
p {
  margin: 0 0 1rem;
}
blockquote {
  border-color: #ffb739;
  font-size: 1.6rem;
}
hr {
  border-color: #ffb739;
}

.ttu {
  text-transform: uppercase;
}
.h-100 {
  height: 100%;
}
.mt-auto {
  margin-top: auto;
}
.mr-10 {
  margin-right: 10px;
}
ul:not(.list-unstyled):not(.list-inline) {
  padding: 0;
}
ul:not(.list-unstyled):not(.list-inline) li {
  list-style: none;
  padding-left: 0.5em;
  position: relative;
  margin-bottom: 0.25em;
}
ul:not(.list-unstyled):not(.list-inline) li::before {
  display: block;
  content: "";
  width: 0.2em;
  height: 0.2em;
  background: currentColor;
  position: absolute;
  left: 0;
  top: 0.6em;
}
.btn {
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  vertical-align: top;
  text-decoration: none;
}
.btn:hover {
  opacity: 0.8;
}
.btn:active {
  opacity: 1;
}
.transparent-btn {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}
.transparent-btn .dotted {
  font-size: 16px;
  display: inline-block;
  position: relative;
  white-space: nowrap;
}
.transparent-btn .dotted::after {
  display: block;
  content: '...........................................................................................................';
  position: absolute;
  width: 100%;
  overflow: hidden;
  left: 0;
  bottom: -0.45em;
  transition: color 0.25s;
}
.transparent-btn:hover {
  text-decoration: none;
}
.transparent-btn:hover .dotted::after {
  color: transparent;
}
.common-btn {
  display: inline-block;
  padding: 15px 40px;
  position: relative;
  background-color: #e4d7cd;
  border: 1px solid #e4d7cd;
  color: #0f0f0f;
  white-space: nowrap;
  transition: box-shadow 0.25s;
  line-height: 1;
  text-decoration: none;
  border-radius: 26px;
}
.common-btn .text {
  text-align: center;
  font-size: 20px;
  display: block;
  font-weight: normal;
  line-height: 1;
  pointer-events: none;
  vertical-align: middle;
  color: inherit;
  text-transform: uppercase;
}
.common-btn strong {
  font-weight: bold;
}
.common-btn:hover {
  text-decoration: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  color: #0f0f0f;
}
.common-btn:focus {
  text-decoration: none;
  box-shadow: 0 0 0 0 transparent;
  color: #0f0f0f;
}
.common-btn:active {
  box-shadow: 0 0 0 0 transparent;
  top: 1px;
  color: #0f0f0f;
}
.common-btn:active:focus {
  box-shadow: 0 0 0 0 transparent;
  top: 1px;
  color: #0f0f0f;
}
.btn-block {
  display: block;
}
.close-btn,
.mfp-close-btn-in .mfp-close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 44px;
  height: 44px;
  line-height: 44px;
  font-style: normal;
  font-size: 28px;
  border-radius: 50%;
  font-family: Arial, Baskerville, monospace;
  color: #c32328;
  background-color: white;
  opacity: 1;
  z-index: 10;
  text-align: center;
  padding: 0;
}
.close-btn:hover,
.mfp-close-btn-in .mfp-close:hover,
.close-btn:focus,
.mfp-close-btn-in .mfp-close:focus,
.close-btn:active,
.mfp-close-btn-in .mfp-close:active,
.close-btn:active:focus,
.mfp-close-btn-in .mfp-close:active:focus {
  color: #c32328;
}
.mfp-close-btn-in .mfp-close {
  top: 0;
  right: 0;
}
@media (min-width: 768px) {
  .mfp-close-btn-in .mfp-close {
    top: 15px;
    right: 15px;
  }
}
.red-btn {
  background: #c32328;
  border-color: #c32328;
  color: white;
}
.red-btn:hover,
.red-btn:focus,
.red-btn:active,
.red-btn:active:focus {
  color: white;
}
.main-btn {
  background: transparent;
  border-color: #ffb739;
  border-radius: 2px;
  padding: 0 0 0 2.8rem;
  transition: all 0.25s;
}
.main-btn::before {
  display: block;
  content: "";
  width: 3px;
  height: 50%;
  border-radius: 2px;
  background: #ffb739;
  position: absolute;
  left: -2px;
  top: 50%;
  transform: translateY(-50%);
}
.main-btn .text {
  display: block;
  background: #ffb739;
  padding: 1.4rem 2rem;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2rem;
  color: white;
  line-height: 1;
  text-align: center;
  transition: all 0.25s;
}
.main-btn .text.text-left {
  text-align: left;
}
.main-btn:hover {
  padding-left: 0;
}
.main-btn:hover .text {
  padding-left: 3.1em;
  padding-right: 3.1rem;
}
.wide-btn .text {
  padding: 1.4rem 4rem;
}
.wide-btn:hover .text {
  padding-left: 5.4rem;
  padding-right: 5.4rem;
}
.menu-btn {
  background: #ffb739;
  border-color: #ffb739;
  color: white;
  border-radius: 3px;
}
input[type="text"],
textarea,
select,
input[type="password"] {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  font-family: 'Akzidenz-Grotesk Pro Cnd', Arial, sans-serif;
  font-size: 18px;
  line-height: 20px;
}
label {
  cursor: pointer;
}
button {
  outline: none;
  cursor: pointer;
}
input:focus {
  outline: none;
}
input::-webkit-input-placeholder {
  opacity: 0.8;
}
input::-moz-placeholder {
  opacity: 0.8;
}
select:not(.unstyled) {
  display: none;
}
.jq-number {
  display: block;
}
.error-bubble {
  position: relative;
  display: block;
}
.error-bubble .error {
  position: absolute;
  display: block;
  width: 300px;
  bottom: 100%;
  left: -5px;
  transform: translateY(-100%);
  background: white;
  border: 1px solid #c32328;
  color: #c32328;
  padding: 5px 10px;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.25);
  pointer-events: none;
}
.form-control,
.jq-number input,
.form-control-div {
  display: block;
  width: 100%;
  padding: 1.5rem 4rem;
  height: 6.6rem;
  border: 1px solid white;
  background-color: white;
  border-radius: 3.3rem;
  box-shadow: none;
  font-size: 3rem;
}
@media (min-width: 768px) {
  .form-control,
  .jq-number input,
  .form-control-div {
    padding: 1.5rem 4rem;
    height: 4.4rem;
    border-radius: 2.2rem;
    font-size: 2rem;
  }
}
.form-control:focus,
.jq-number input:focus,
.form-control-div:focus {
  border-color: #ffb739;
  box-shadow: none;
}
.form-control::placeholder,
.jq-number input::placeholder,
.form-control-div::placeholder {
  font-weight: 300;
  font-size: 3rem;
  opacity: 1;
  color: #0f0f0f;
}
.form-control::-moz-placeholder,
.jq-number input::-moz-placeholder,
.form-control-div::-moz-placeholder {
  font-weight: 300;
  font-size: 3rem;
  opacity: 1;
  color: #0f0f0f;
}
.form-control::-ms-input-placeholder,
.jq-number input::-ms-input-placeholder,
.form-control-div::-ms-input-placeholder {
  font-weight: 300;
  font-size: 3rem;
  opacity: 1;
  color: #0f0f0f;
}
.form-control::-webkit-input-placeholder,
.jq-number input::-webkit-input-placeholder,
.form-control-div::-webkit-input-placeholder {
  font-weight: 300;
  font-size: 3rem;
  opacity: 1;
  color: #0f0f0f;
}
@media (min-width: 768px) {
  .form-control::placeholder,
  .jq-number input::placeholder,
  .form-control-div::placeholder {
    font-size: 2rem;
  }
  .form-control::-moz-placeholder,
  .jq-number input::-moz-placeholder,
  .form-control-div::-moz-placeholder {
    font-size: 2rem;
  }
  .form-control::-ms-input-placeholder,
  .jq-number input::-ms-input-placeholder,
  .form-control-div::-ms-input-placeholder {
    font-size: 2rem;
  }
  .form-control::-webkit-input-placeholder,
  .jq-number input::-webkit-input-placeholder,
  .form-control-div::-webkit-input-placeholder {
    font-size: 2rem;
  }
}
.form-control.error,
.jq-number input.error,
.form-control-div.error {
  border-color: #c32328 !important;
}
textarea.form-control::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
textarea.form-control::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e5e6e7;
}
textarea.form-control::-webkit-scrollbar-thumb {
  background-color: #949494;
  cursor: all-scroll;
}
textarea.form-control::-webkit-scrollbar-thumb:hover {
  background-color: #c32328;
}
.jq-selectbox__select {
  padding: 0 35px 0 13px;
  border-radius: 3px;
  font: 16px/34px 'Akzidenz-Grotesk Pro Cnd', Arial, sans-serif;
  color: #a7a7a7;
  border: 1px solid #b8b8b8;
  background: white;
}
@media (min-width: 768px) {
  .jq-selectbox__select {
    font: 16px/50px 'Akzidenz-Grotesk Pro Cnd', Arial, sans-serif;
  }
}
.opened .jq-selectbox__select {
  border-color: #c32328;
  box-shadow: none;
}
.jq-selectbox__select-text {
  display: block;
  width: 100% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-size: 16px;
}
.jq-selectbox__select-text.placeholder {
  color: #a7a7a7;
}
.jq-selectbox__trigger {
  position: absolute;
  top: 0;
  right: 0;
  width: 34px;
  height: 100%;
  font-size: 24px;
  color: #999;
}
.jq-selectbox__trigger-arrow {
  position: absolute;
  top: 50%;
  margin-top: -18px;
  right: 9px;
  display: inline-block;
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
  font-family: Arial, Baskerville, monospace;
  color: #c32328;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.25s, margin 0.25s;
  transform: scale(1, 0.8);
}
.jq-selectbox__trigger-arrow::before {
  content: "▼";
}
@media (min-width: 768px) {
  .jq-selectbox__trigger-arrow {
    margin-top: -25px;
  }
}
.opened .jq-selectbox__trigger-arrow {
  transform: scale(1, -0.8);
  margin-top: -20px;
}
@media (min-width: 768px) {
  .opened .jq-selectbox__trigger-arrow {
    margin-top: -27px;
  }
}
.jq-selectbox__dropdown {
  box-sizing: border-box;
  width: 100%;
  margin: 2px 0 0;
  padding: 0;
  border: 1px solid #CCC;
  background: #FFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  font: 14px/18px 'Akzidenz-Grotesk Pro Cnd', Arial, sans-serif;
  text-align: left;
  min-width: 100%;
}
.jq-selectbox__dropdown ul {
  max-height: 50vh;
  overflow: auto;
}
.jq-selectbox__dropdown ul::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.jq-selectbox__dropdown ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e5e6e7;
}
.jq-selectbox__dropdown ul::-webkit-scrollbar-thumb {
  background-color: #949494;
  cursor: all-scroll;
}
.jq-selectbox__dropdown ul::-webkit-scrollbar-thumb:hover {
  background-color: #c32328;
}
.jq-selectbox__search {
  margin: 5px;
}
.jq-selectbox__search input {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 5px 27px 6px 8px;
  border: 1px solid #CCC;
  border-radius: 0;
  outline: none;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO1JREFUeNqU078LAXEYx/FzYfNzk5TJaFNKYjFYSQZ/hvwBsvg1UCY2xT9gM8hukQGThWRjkcFw3pdnujhfT736Xn2fPvfc3fd07V0OFDDFAnM0ENYsZRiGLSc9OpqIYIA9fMhhjCrW2h9VlMlcH/aymMGtOqEugX08PwQucUZKdTozMIqdTc9WepQD7wjY9ARx+ydwhfyXfS+S0qMcOEQJGcueB3VccFINdMgal6NzkmPjRwJXxDBB7/2RDdtAp6wb+dpphHDASG5QQ0V6u2aoSqBZD/lDrNWRJynLK2qpBn4rc6K2XB9/Nb8EGABtf1thzY6X2AAAAABJRU5ErkJggg==') no-repeat 100% 50%;
  box-shadow: inset 1px 1px #F1F1F1;
  color: #333;
}
.jq-selectbox__not-found {
  margin: 5px;
  padding: 5px 8px 6px;
  background: #F0F0F0;
  font-size: 13px;
}
.jq-selectbox ul {
  margin: 0;
  padding: 0;
  border-radius: 0;
}
.jq-selectbox li {
  padding: 7px 10px 5px !important;
  color: #0f0f0f;
  margin-bottom: 0 !important;
}
.jq-selectbox li::before {
  display: none !important;
}
.jq-selectbox li.selected {
  background-color: #e5e6e7;
  color: #0f0f0f;
}
.jq-selectbox li:hover {
  background-color: #c32328;
  color: white;
}
.jq-selectbox li.disabled {
  color: #AAA;
}
.jq-selectbox li.disabled:hover {
  background: none;
}
.jq-selectbox li.optgroup {
  font-weight: bold;
}
.jq-selectbox li.optgroup:hover {
  background: none;
  color: #0f0f0f;
  cursor: default;
}
.jq-selectbox li.option {
  padding-left: 25px;
}
.jq-file {
  display: block;
  width: 100%;
  padding: 0.5em 4rem;
  height: 6.6rem;
  border: 1px solid #b36654;
  background-color: #b36654;
  border-radius: 3.3rem;
  box-shadow: none;
}
@media (min-width: 768px) {
  .jq-file {
    padding: 0.5rem 4rem;
    height: 4.4rem;
    border-radius: 2.2rem;
    font-size: 2rem;
  }
}
.jq-file:hover {
  text-decoration: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
}
.jq-file:focus {
  text-decoration: none;
  box-shadow: 0 0 0 0 transparent;
}
.jq-file:active {
  box-shadow: 0 0 0 0 transparent;
  top: 1px;
}
.jq-file input {
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.jq-file .jq-file__name {
  font-size: 3rem;
  font-weight: normal;
  display: inline;
  color: white;
}
@media (min-width: 768px) {
  .jq-file .jq-file__name {
    font-size: 2rem;
  }
}
.jq-file .jq-file__browse {
  display: none;
}
.jq-file.jq-file:hover .jq-file__browse {
  color: #c32328;
}
.jq-file.jq-file:active .jq-file__browse {
  color: #c32328;
}
.jq-file.jq-file.disabled,
.jq-file.jq-file.disabled .jq-file__name,
.jq-file.jq-file.disabled .jq-file__browse {
  border-color: #CCC;
  background: #F5F5F5;
  box-shadow: none;
  color: #888;
}
.upload-wrap label {
  display: block;
  font-size: 1.6rem;
  font-weight: normal;
}
.upload-wrap .jq-file {
  padding: 0.8rem 3rem;
  border-radius: 3px;
  background: #d1d1d1;
}
.upload-wrap .jq-file__name {
  font-size: 1.2rem;
  font-weight: bold;
}
input[type=checkbox]:not(.unstyled),
input[type=radio]:not(.unstyled) {
  display: none;
}
.jq-checkbox,
.jq-radio {
  vertical-align: -8px;
  width: 24px;
  height: 24px;
  margin: 0 4px 0 0;
  border: 1px solid #C3C3C3;
  background: white;
  cursor: pointer;
  box-sizing: content-box;
}
.jq-checkbox.focused,
.jq-radio.focused {
  border: 1px solid #ffb739;
}
.jq-checkbox.disabled,
.jq-radio.disabled {
  opacity: 0.55;
}
.jq-checkbox {
  border-radius: 3px;
}
.jq-checkbox + span {
  max-width: calc(100% - 30px);
  vertical-align: top;
}
.jq-checkbox.checked {
  box-shadow: inset 0 0 0 2px white;
  background: #ffb739;
}
.jq-checkbox.checked .jq-checkbox__div {
  width: 9px;
  height: 5px;
  margin: 6px 0 0 6px;
  border-bottom: 3px solid white;
  border-left: 3px solid white;
  transform: rotate(-50deg);
  box-sizing: content-box;
}
.jq-radio {
  border-radius: 50%;
}
.jq-radio.checked {
  background: #ffb739;
  border-color: #ffb739;
}
.jq-radio.checked .jq-radio__div {
  width: 12px;
  height: 12px;
  margin: 6px 0 0 6px;
  border-radius: 50%;
  background: white;
}
.jq-checkbox,
.jq-radio {
  vertical-align: -8px;
  width: 24px;
  height: 24px;
  margin: 0 4px 0 0;
  border: 1px solid #C3C3C3;
  background: white;
  cursor: pointer;
  box-sizing: content-box;
}
.doc-type-selector {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.doc-type-selector .selector-item span {
  display: block;
  width: 235px;
  height: 50px;
  border: 1px solid #a7a7a7;
  background: #f5f5f5;
  position: relative;
  color: #a7a7a7;
  text-transform: uppercase;
  line-height: 50px;
  font-size: 16px;
  font-weight: normal;
  padding-left: 50px;
}
.doc-type-selector .selector-item span::before {
  display: block;
  content: "";
  width: 18px;
  height: 18px;
  background: #a7a7a7;
  position: absolute;
  top: 15px;
  left: 15px;
}
.doc-type-selector .selector-item:not(:first-child) span {
  margin-left: -1px;
}
.doc-type-selector .selector-item input:checked + span {
  border-color: #ffb739;
  z-index: 1;
  color: #0f0f0f;
}
.doc-type-selector .selector-item input:checked + span::before {
  background: #ffb739;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-70 {
  margin-bottom: 70px;
}
@-ms-viewport {
  width: device-width;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
.slider-block > .slide ~ .slide {
  display: none;
}
.slick-dots {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  font-size: 0;
}
.slick-dots button {
  border: none;
  padding: 0;
  background: white;
  width: 7.7rem;
  height: 3px;
  border-radius: 3px;
  transition: background 0.75s;
}
.slick-dots li.slick-active button {
  background: #c32328;
}
.slick-dots li ~ li {
  margin-left: 2.3rem;
}
.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  margin: 0;
  display: block;
  width: 4.5rem;
  height: 4.5rem;
  background: #e4d7cd;
  border: none;
  border-radius: 50%;
  z-index: 1;
  transition: background 0.25s;
}
.slick-arrow:hover {
  background: #c32328;
}
.slick-arrow span {
  display: block;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.5rem;
  margin-left: -0.5rem;
  transform: rotate(-45deg);
}
.slick-arrow.slick-prev {
  left: -1rem;
}
@media (max-width: 767px) {
  .slick-arrow.slick-prev {
    left: 1rem;
  }
}
.slick-arrow.slick-prev span {
  border-left: 2px solid white;
  border-top: 2px solid white;
}
.slick-arrow.slick-next {
  right: -1rem;
}
@media (max-width: 767px) {
  .slick-arrow.slick-next {
    right: 1rem;
  }
}
.slick-arrow.slick-next span {
  border-right: 2px solid white;
  border-bottom: 2px solid white;
}

.header {
  background-color: #c32328;
  color: white;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  position: sticky;
  top: 0;
  z-index: 500;
}
.header a {
  color: white;
  text-decoration: none;
}
.header a:hover {
  color: #f2f2f2;
  text-decoration: none;
}
.header a:focus {
  color: white;
  text-decoration: none;
}
.header a:active,
.header a:active:focus {
  color: #e6e6e6;
  text-decoration: none;
}
.header .col-phone {
  font-size: 18px;
  white-space: nowrap;
}
.header .col-phone span {
  font-size: 1.66666667em;
  font-weight: 500;
}
.header .form-wrap {
  position: relative;
  max-width: 116px;
  min-width: 116px;
  transition: all 0.25s;
}
.header .form-wrap button {
  position: absolute;
  right: 0;
  top: 3px;
  background-color: transparent;
  border: none;
  font-size: 18px;
}
.header .form-wrap input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  padding-left: 12px;
  padding-right: 30px;
  width: 100%;
  padding-bottom: 5px;
}
.header .form-wrap input::placeholder {
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  opacity: 1;
}
.header .form-wrap input::-moz-placeholder {
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  opacity: 1;
}
.header .form-wrap input::-ms-input-placeholder {
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  opacity: 1;
}
.header .form-wrap input::-webkit-input-placeholder {
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  color: white;
  opacity: 1;
}
.header .form-wrap input:focus::placeholder {
  opacity: 0;
}
.header .form-wrap input:focus::-moz-placeholder {
  opacity: 0;
}
.header .form-wrap input:focus::-ms-input-placeholder {
  opacity: 0;
}
.header .form-wrap input:focus::-webkit-input-placeholder {
  opacity: 0;
}
.header .main-nav ul:not(.lang-list) {
  margin: 0 -15px;
  display: flex;
  align-items: center;
}
.header .main-nav ul:not(.lang-list) a {
  text-transform: uppercase;
  font-size: 20px;
  position: relative;
  display: block;
  border-radius: 23px;
  height: 45px;
  line-height: 43px;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid transparent;
  white-space: nowrap;
}
.header .main-nav ul:not(.lang-list) a .total {
  position: absolute;
  display: block;
  background-color: white;
  color: #c32328;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 0.75em;
  border-radius: 50%;
  top: -15px;
  right: -15px;
}
.header .main-nav ul:not(.lang-list) li.active a {
  border-color: white;
}
.header .col-logo {
  max-width: 245px;
  transition: all 0.25s;
}
.header.sticked .col-logo {
  max-width: 110px;
}
.header.sticked .form-wrap {
  max-width: 150px;
}
@media (max-width: 991px) {
  .header .col-logo {
    order: 1;
    max-width: 110px;
  }
  .header .col-non-logo {
    order: 2;
  }
  .header .col-menu {
    order: 4;
    overflow: visible;
  }
  .header .col-menu .main-nav {
    position: fixed;
    top: 75px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 75px);
    background-color: #c32328;
    transform: translateX(100%);
    transition: all 0.4s;
    padding: 30px;
  }
  .header .col-menu .main-nav.main-nav-open {
    transform: translateX(0%);
  }
  .header .col-menu .main-nav ul {
    display: block;
  }
  .header .col-menu .main-nav ul a {
    display: inline-block;
  }
  .header .col-search {
    order: 2;
    max-width: 300px !important;
  }
  .header .col-phone {
    order: 3;
  }
}
@media (max-width: 767px) {
  .header .col-menu .main-nav {
    top: 57px;
    height: calc(100vh - 57px);
  }
}
.lang-list {
  text-align: right;
}
.lang-list a {
  opacity: 0.75;
}
.lang-list a.active {
  opacity: 1;
}
.header .row-top {
  max-height: 28px;
  transition: all 0.25s;
}
.header.sticked .row-top {
  margin-top: -10px;
  max-height: 0;
  opacity: 0;
  pointer-events: none;
}
.menu-icon-wrapper {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  margin: 23px 15px 23px 23px;
  pointer-events: none;
  transition: 0.1s;
}
.menu-icon-wrapper svg {
  position: absolute;
  top: -33px;
  left: -33px;
  transform: scale(0.1);
  transform-origin: 0 0;
}
.menu-icon-wrapper svg path {
  stroke: #fff;
  stroke-width: 50px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: transparent;
}
.menu-icon-wrapper .nav-trigger {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  pointer-events: auto;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}
svg:not(:root) {
  overflow: hidden;
}
.mobile-btn-wrap {
  margin: -23px -15px -23px -23px;
  overflow: hidden;
}
.footer {
  background-color: #c32328;
  color: white;
  padding-top: 5rem;
  padding-bottom: 4rem;
  transform: translateZ(0) scale(1, 1);
  font-size: 16px;
}
.footer .rich-text-wrap {
  font-size: 16px;
}
.footer .container {
  position: relative;
}
.footer .lang-list a {
  color: white;
  text-decoration: none;
}
.footer .lang-list a:hover {
  color: #f2f2f2;
  text-decoration: none;
}
.footer .lang-list a:focus {
  color: white;
  text-decoration: none;
}
.footer .lang-list a:active,
.footer .lang-list a:active:focus {
  color: #e6e6e6;
  text-decoration: none;
}
@media (min-width: 768px) {
  .footer .lang-list {
    position: absolute;
    right: 15px;
    bottom: 0;
  }
}
.main-slider {
  padding-bottom: 4rem;
}
.main-slider .slick-list {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 28px 59px 0px rgba(163, 133, 102, 0.3);
}
.main-slider .slick-slide .slide {
  display: block!important;
}
.main-slider .slide {
  position: relative;
}
.main-slider .slide .content-wrap {
  position: absolute;
  padding: 3em 4em;
  top: 0;
  left: 0;
  max-width: 200px;
  box-sizing: content-box;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  border-bottom-right-radius: 10px;
}
@media (min-width: 768px) {
  .main-slider .slide .content-wrap {
    max-width: 400px;
  }
}
@media (min-width: 992px) {
  .main-slider .slide .content-wrap {
    max-width: 500px;
  }
}
.main-slider .slide .content-wrap .title {
  text-transform: uppercase;
  font-size: 3rem;
  margin-bottom: 2rem;
  line-height: 1.1;
}
@media (min-width: 768px) {
  .main-slider .slide .content-wrap .title {
    font-size: 4rem;
  }
}
@media (min-width: 992px) {
  .main-slider .slide .content-wrap .title {
    font-size: 6rem;
  }
}
.main-slider .slide .content-wrap .text {
  font-size: 3rem;
  border-left: 1px solid #c32328;
  padding: 1rem 4rem;
  line-height: 1.2;
}
.flying-cookies {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 170px);
  overflow: hidden;
}
.flying-cookies li {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.flying-cookies li .img-wrap {
  position: absolute;
}
@media (max-width: 1200px) {
  .flying-cookies li:nth-child(1),
  .flying-cookies li:nth-child(2),
  .flying-cookies li:nth-child(3) {
    display: none!important;
  }
}
@media (max-width: 1400px) {
  .flying-cookies li:nth-child(4) {
    display: none!important;
  }
}
@media (max-width: 1500px) {
  .flying-cookies li:nth-child(5),
  .flying-cookies li:nth-child(6) {
    display: none!important;
  }
}
@media (max-width: 1700px) {
  .flying-cookies li:nth-child(5),
  .flying-cookies li:nth-child(6) {
    display: none!important;
  }
}
.main-slider-cookie-bg {
  margin-top: -8rem;
  padding-top: 23rem;
  background-image: url(../img/slider_bg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 1920px 676px;
}
@media (max-width: 767px) {
  .main-slider-cookie-bg {
    background-size: 960px auto;
  }
}
@media (min-width: 992px) {
  .main-slider-cookie-bg {
    min-height: 676px;
  }
}
.main-slider-block {
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;
}
.offer-wrap {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767px) {
  .offer-wrap {
    font-size: 3rem;
  }
}
.catalogue-filter-row {
  margin-bottom: 3.5rem;
}
.catalogue-filter-row fieldset {
  margin: 0;
  padding: 0;
  border: none;
}
.catalogue-filter-row .filter-btn {
  background: #e4d7cd;
  color: #0f0f0f;
  line-height: 1;
  padding: 10px 30px 12px;
  border-radius: 22px;
  margin-right: 1.7rem;
  margin-bottom: 1rem;
  text-decoration: none !important;
}
@media (max-width: 767px) {
  .catalogue-filter-row .filter-btn {
    font-size: 3rem;
  }
}
.catalogue-filter-row .filter-btn:hover,
.catalogue-filter-row .filter-btn:focus,
.catalogue-filter-row .filter-btn:active,
.catalogue-filter-row .filter-btn:active:focus {
  color: #0f0f0f;
}
.catalogue-filter-row .filter-btn.hidden {
  background: #c32328;
  display: inline-block !important;
  color: white;
}
.catalogue-filter-row .filter-btn.hidden:hover,
.catalogue-filter-row .filter-btn.hidden:focus,
.catalogue-filter-row .filter-btn.hidden:active,
.catalogue-filter-row .filter-btn.hidden:active:focus {
  color: white;
}
.catalogue-filter-row label,
.catalogue-filter-row .label {
  display: inline-block;
  font-weight: 300;
  background: #e4d7cd;
  color: #0f0f0f;
  line-height: 1;
  padding: 10px 30px 12px;
  border-radius: 22px;
  margin-right: 1.7rem;
  margin-bottom: 1rem;
  position: relative;
  text-decoration: none !important;
}
@media (max-width: 767px) {
  .catalogue-filter-row label,
  .catalogue-filter-row .label {
    font-size: 3rem;
  }
}
.catalogue-filter-row label:hover,
.catalogue-filter-row .label:hover,
.catalogue-filter-row label:focus,
.catalogue-filter-row .label:focus,
.catalogue-filter-row label:active,
.catalogue-filter-row .label:active,
.catalogue-filter-row label:active:focus,
.catalogue-filter-row .label:active:focus {
  color: #0f0f0f;
}
.catalogue-filter-row label .num,
.catalogue-filter-row .label .num {
  position: absolute;
  top: -10px;
  right: -5px;
  background: #c32328;
  color: white;
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
  font-size: 75%;
  line-height: 30px;
}
.catalogue-filter-row label .num:empty,
.catalogue-filter-row .label .num:empty {
  display: none;
}
.catalogue-filter-row input:checked + label {
  background: #c32328;
  color: white;
}
.catalogue-filter-row input:checked + label .num {
  background: white;
  color: #0f0f0f;
}
.catalogue-tile {
  background: white;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  transition: box-shadow 0.25s;
}
.catalogue-tile .title {
  position: absolute;
  bottom: 3rem;
  left: 3rem;
  right: 0;
  margin: 0;
  font-weight: 300;
  font-size: 24px;
  border-left: 1px solid #e4d7cd;
  padding-left: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.25s;
}
.catalogue-tile .status {
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 70px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 70px;
  color: white;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 14px;
}
.catalogue-tile .status > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  display: block;
  padding-bottom: 20px;
}
.catalogue-tile .status.new {
  background: #c32328;
}
.catalogue-tile .status.action {
  background: #ffb739;
}
.catalogue-tile:hover {
  box-shadow: 6.217px 5.035px 40px 0px rgba(196, 183, 174, 0.75);
}
.catalogue-tile:hover .title {
  border-color: #c32428;
}
@media (max-width: 767px) {
  .catalogue-block {
    overflow: hidden;
  }
}
.catalogue-block .logo-bg {
  padding-bottom: 5.20833333vw;
}
.actions-tile {
  position: relative;
  max-width: 610px;
  margin-left: auto;
  margin-right: auto;
}
.actions-tile .img-wrap {
  border-radius: 5px;
}
.actions-tile .text {
  position: absolute;
  left: 19.5rem;
  top: 50%;
  transform: translateY(-50%);
  font-weight: normal;
  font-size: 2.4rem;
  line-height: 1.2;
}
.actions-tile.light .text {
  color: #0f0f0f;
  text-shadow: 0 0 1px white, 0 0 5px white;
}
.actions-tile.dark .text {
  color: white;
  text-shadow: 0 0 1px black, 0 0 5px black;
}
.actions-tile .discount {
  background: #c32328;
  color: white;
  width: 11rem;
  height: 11rem;
  border-radius: 50%;
  position: absolute;
  left: 5rem;
  top: 50%;
  transform: translateY(-50%);
  padding: 1.5rem;
}
.actions-tile .discount .border {
  border-radius: 50%;
  border: 0.2rem dotted white;
  text-align: center;
  white-space: nowrap;
}
.actions-tile .discount .border::before {
  display: inline-block;
  content: "";
  height: 7.6rem;
  vertical-align: middle;
}
.actions-tile .discount .border .number {
  font-size: 3.5rem;
  font-weight: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}
.actions-tile .discount .border .percent {
  font-size: 2rem;
  font-weight: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}
.production-block {
  position: relative;
  overflow: hidden;
  margin-top: -2.08333333vw;
  margin-bottom: -2.08333333vw;
}
@media (min-width: 768px) {
  .production-block {
    padding-top: 6.25vw;
    margin-top: -6.25vw;
    margin-bottom: -8.33333333vw;
    padding-bottom: 6.25vw;
  }
}
@media (min-width: 1920px) {
  .production-block {
    padding-top: 120px;
    margin-top: -120px;
    margin-bottom: -160px;
    padding-bottom: 120px;
  }
}
.production-block image {
  mask: url(#productionMask);
}
.production-block .production-bars {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  margin-left: -15.625vw;
  width: 67.39583333vw;
  height: 63.38541667vw;
}
@media (max-width: 767px) {
  .production-block .production-bars {
    display: none;
  }
}
@media (min-width: 1920px) {
  .production-block .production-bars {
    margin-left: -300px;
    width: 1294px;
    height: 1217px;
  }
}
.production-block .production-cookies {
  position: absolute;
  top: 50%;
  left: 0;
  width: 42.08333333vw;
  height: 33.54166667vw;
  margin-top: -5.20833333vw;
}
@media (max-width: 1200px) {
  .production-block .production-cookies {
    display: none;
  }
}
@media (min-width: 1920px) {
  .production-block .production-cookies {
    width: 808px;
    height: 644px;
    margin-top: -100px;
  }
}
.production-block .rich-text-wrap {
  text-shadow: 0 0 1px black, 0 0 5px black;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 5.20833333vw;
  color: white;
  transform: scale(1) translateZ(0);
  font-size: 2rem;
}
@media (min-width: 768px) {
  .production-block .rich-text-wrap {
    padding-top: calc(5.72916667vw + 5.20833333vw);
  }
}
@media (min-width: 1920px) {
  .production-block .rich-text-wrap {
    padding-top: 210px;
  }
}
.production-block .rich-text-wrap .main-title {
  margin-bottom: 3rem;
}
.production-block .rich-text-wrap .content {
  max-width: 100%;
}
@media (min-width: 768px) {
  .production-block .rich-text-wrap .content {
    max-width: 45%;
  }
}
.partners-block {
  color: #0f0f0f;
  font-size: 24px;
  padding-top: 6rem;
}
@media (min-width: 768px) {
  .partners-block {
    padding-top: 12rem;
  }
}
.partners-block .main-title {
  margin-bottom: 3rem;
}
.partners-block .border-block {
  border-color: #0f0f0f;
}
.partners-block .border-block::before {
  background: #0f0f0f;
}
@media (max-width: 767px) {
  .partners-block .border-block {
    font-size: 3rem;
  }
}
.partners-slider {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.partners-slider .slide {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.partners-slider .partner-logo .img-wrap {
  border-radius: 50%;
  overflow: hidden;
  width: 185px;
  height: 185px;
  margin: auto;
  background: white;
  padding: 10px;
}
.partners-slider .partner-logo .img-wrap::before {
  display: inline-block;
  content: "";
  height: 165px;
  vertical-align: middle;
}
.dealer-form-block {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
@media (min-width: 768px) {
  .dealer-form-block {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }
}
.dealer-form-block .logo {
  position: relative;
  max-width: 215px;
  margin: auto;
  overflow: visible;
}
.dealer-form-block .logo::before {
  display: block;
  content: url(../img/ribbon.png);
  width: 879px;
  height: 400px;
  position: absolute;
  right: 100%;
  top: 0;
}
.dealer-form-block .content {
  max-width: 540px;
  margin: auto;
}
@media (max-width: 767px) {
  .dealer-form-block .content {
    font-size: 3rem;
  }
}
.dealer-form-block .dealer-form {
  max-width: 700px;
  margin: auto;
}
.news-tile {
  position: relative;
}
.news-tile .image-col {
  flex-grow: 1000;
  flex-shrink: 1;
}
.news-tile .content-col {
  flex-grow: 1;
  flex-shrink: 1000;
}
.news-tile .img-wrap {
  position: relative;
}
.news-tile .img-wrap img {
  border-radius: 3px;
}
.news-tile .img-wrap .label {
  position: absolute;
  display: block;
  top: 25px;
  left: 0;
  width: 70px;
  background-color: #c32328;
  color: white;
  font-size: 16px;
  text-align: center;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  line-height: 30px;
}
.news-tile .date-label {
  font-size: 20px;
  font-weight: normal;
  color: #c32328;
  line-height: 1;
}
.news-tile .news-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 20px;
  font-weight: bold;
  color: #c32328;
}
.news-tile .rich-text-wrap {
  line-height: 1.2;
  font-size: 20px;
}
.news-block {
  padding-bottom: 4rem;
}
.video-img-wrap {
  position: relative;
}
.video-img-wrap .video-play-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.brand-tile {
  background: url(../img/brand_bg.jpg) no-repeat center;
  background-size: cover;
  border-radius: 5px;
  overflow: hidden;
  min-height: 400px;
  position: relative;
  box-shadow: 6.217px 5.035px 40px 0px rgba(224, 210, 162, 0.75);
}
@media (min-width: 768px) {
  .brand-tile {
    height: 530px;
  }
}
.brand-tile .title {
  position: absolute;
  left: 4rem;
  bottom: 4rem;
  font-size: 6rem;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1;
}
.slick-slider .brand-tile {
  box-shadow: none;
}
.brand-slider {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -25px;
}
.brand-slider .slide {
  padding-left: 15px;
  padding-right: 15px;
}
.brand-slider .slick-list {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
}
.popup {
  position: relative;
  max-width: 1240px;
  margin: auto;
  padding: 3rem;
  border-radius: 5px;
  background-color: #ece2dd;
}
.popup .form-title {
  color: #c32328;
  margin: 0;
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 2rem;
}

html {
  font-size: 5px;
}
html::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
html::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e5e6e7;
}
html::-webkit-scrollbar-thumb {
  background-color: #949494;
  cursor: all-scroll;
}
html::-webkit-scrollbar-thumb:hover {
  background-color: #c32328;
}
@media (max-width: 991px) {
  html.popup-open {
    overflow: hidden;
  }
}
@media (min-width: 768px) {
  html {
    font-size: 10px;
  }
}
@media (min-width: 1366px) {
  .container {
    width: 1270px;
  }
}
@media (min-width: 1600px) {
  .wide-container {
    margin-left: -17rem;
    margin-right: -17rem;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.page-title {
  font-size: 4.8rem;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.rich-text-wrap {
  font-size: 2rem;
}
.rich-text-wrap,
.mce-content-body {
  font-weight: 300;
}
.rich-text-wrap .table-responsive,
.mce-content-body .table-responsive {
  min-width: 100%;
}
.rich-text-wrap .table-striped,
.mce-content-body .table-striped {
  border-color: #e4d7cd;
}
.rich-text-wrap .table-striped *,
.mce-content-body .table-striped * {
  border-color: #e4d7cd;
}
.rich-text-wrap .table-striped td,
.mce-content-body .table-striped td {
  padding: 1rem;
  text-align: left;
}
.rich-text-wrap .table-striped thead,
.mce-content-body .table-striped thead {
  background: #e4d7cd;
}
body:not(.mce-content-body) {
  background-image: url(../img/bg_pattern.jpg);
}
body.mce-content-body {
  font-size: 20px;
}
@media (min-width: 1920px) {
  body {
    max-width: 1920px;
    margin: auto;
  }
}
.logo-bg {
  background-image: url(../img/bg_pattern2.png);
}
.link-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
}
.pdo-page .pagination {
  display: none !important;
}
.svg-defs {
  position: absolute;
  width: 0;
  height: 0;
}
.main-title {
  font-size: 4rem;
  font-weight: 300;
}
@media (min-width: 768px) {
  .main-title {
    font-size: 5rem;
  }
}
@media (min-width: 992px) {
  .main-title {
    font-size: 6rem;
  }
}
.main-title strong {
  font-weight: 500;
}
.red-title {
  color: #c32328;
}
.border-block {
  padding-left: 3.5rem;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
  border-left: 1px solid white;
  position: relative;
}
@media (max-width: 767px) {
  .border-block {
    font-size: 2.8rem;
  }
}
.border-block::before {
  display: block;
  content: "";
  position: absolute;
  top: 2.5rem;
  left: -3px;
  width: 5px;
  background: white;
  height: 50px;
  border-radius: 2px;
}
.breadcrumb {
  margin-left: -10px !important;
  margin-bottom: 30px;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 300;
}
.breadcrumb a {
  color: rgba(15, 15, 15, 0.5);
  text-decoration: none;
}
.breadcrumb a:hover {
  color: rgba(2, 2, 2, 0.5);
  text-decoration: none;
}
.breadcrumb a:focus {
  color: rgba(15, 15, 15, 0.5);
  text-decoration: none;
}
.breadcrumb a:active,
.breadcrumb a:active:focus {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
}
.breadcrumb a {
  display: inline-block;
}
.breadcrumb li {
  display: inline-block;
  padding-left: 10px !important;
  padding-right: 10px;
  text-indent: initial !important;
}
.page-title-block .main-title {
  margin-bottom: 4rem;
}
#pdopage .pagination {
  display: none !important;
}
.fotorama > .img-wrap + .img-wrap {
  display: none;
}
@media (min-width: 992px) {
  .contacts-bg {
    background-image: url(../img/contacts_bg/bottom.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    padding-bottom: 275px;
  }
}
@media (min-width: 1600px) {
  .contacts-bg {
    min-height: 800px;
    background-image: url(../img/contacts_bg/left_top.png), url(../img/contacts_bg/right_top.png), url(../img/contacts_bg/bottom.png);
    background-repeat: no-repeat;
    background-position: 0 0, 100% 0, center bottom;
  }
}
@media (min-width: 768px) {
  .contacts-page .columns {
    column-count: 2;
    column-gap: 30px;
  }
}
.contacts-page .columns > * {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid-column;
}
.contacts-page .rich-text-wrap {
  font-size: 3rem;
}
@media (min-width: 768px) {
  .contacts-page .rich-text-wrap {
    font-size: 2.4rem;
  }
}
@media (min-width: 1600px) {
  .common-bg {
    min-height: 1000px;
    background-image: url(../img/common_bg/left-top.png), url(../img/common_bg/right-top.png);
    background-repeat: no-repeat;
    background-position: 0 0, 100% 0;
  }
}
.common-bg .commom-content {
  background-color: white;
  border-radius: 10px;
  padding: 3rem;
}
.mfp-img {
  background: white;
}
.captcha-div {
  text-align: center;
}
.captcha-div > div {
  margin: 0 auto;
}
.wellcome-block image {
  mask: url(#wellcomeMask);
}
.wheat-block {
  background: #ffeeaf url(../img/wheat_bg.jpg) no-repeat center top;
  background-size: 1920px auto;
}
@media (min-width: 1920px) {
  .wheat-block {
    background-size: 100% auto;
  }
}
.catalogue-bg {
  min-height: calc(100vh - 394px);
}
@media (min-width: 1600px) {
  .catalogue-bg {
    min-height: 1000px;
    background-image: url(../img/catalogue_bg/left_top.png), url(../img/catalogue_bg/right_bottom.png), url(../img/catalogue_bg/left_bottom.png), url(../img/catalogue_bg/right_top.png);
    background-repeat: no-repeat;
    background-position: 0 0,
    100% 100%,
    0 100%,
    100% 0;
  }
}
.product-article-wrap {
  display: block;
  background: white;
  border-radius: 6px;
  padding: 3rem;
  position: relative;
}
.product-article-wrap thead td {
  white-space: nowrap;
}
.product-article-wrap .fotorama__thumb-border {
  border-color: #c32328;
}
.product-article-wrap .product-title {
  font-size: 4rem;
  font-weight: normal;
}
.product-article-wrap .rich-text-wrap {
  font-size: 2.4rem;
  line-height: 1.2;
}
.product-page {
  overflow: hidden;
}
.news-filter-row {
  margin-bottom: 3.5rem;
}
.news-filter-row fieldset {
  margin: 0;
  padding: 0;
  border: none;
}
.news-filter-row .filter-btn {
  display: block;
  background: #e4d7cd;
  color: #0f0f0f;
  line-height: 1;
  padding: 21px 0 18px;
  border-radius: 50%;
  margin-bottom: 1rem;
  font-size: 20px;
  text-align: center;
  width: 84px;
  height: 84px;
  text-decoration: none!important;
}
.news-filter-row .filter-btn:hover,
.news-filter-row .filter-btn:focus,
.news-filter-row .filter-btn:active,
.news-filter-row .filter-btn:active:focus {
  color: #0f0f0f;
}
.news-filter-row .filter-btn.hidden {
  background: #c32328;
  display: block !important;
  color: white;
}
.news-filter-row .filter-btn.hidden:hover,
.news-filter-row .filter-btn.hidden:focus,
.news-filter-row .filter-btn.hidden:active,
.news-filter-row .filter-btn.hidden:active:focus {
  color: white;
}
.news-filter-row label,
.news-filter-row .label {
  display: block;
  font-weight: 300;
  background: #e4d7cd;
  color: #0f0f0f;
  line-height: 1;
  padding: 21px 0 18px;
  border-radius: 50%;
  margin-bottom: 1rem;
  position: relative;
  font-size: 20px;
  text-align: center;
  width: 84px;
  height: 84px;
  text-decoration: none!important;
}
.news-filter-row label:hover,
.news-filter-row .label:hover,
.news-filter-row label:focus,
.news-filter-row .label:focus,
.news-filter-row label:active,
.news-filter-row .label:active,
.news-filter-row label:active:focus,
.news-filter-row .label:active:focus {
  color: #0f0f0f;
}
.news-filter-row label .num,
.news-filter-row .label .num {
  position: absolute;
  top: -10px;
  right: -5px;
  background: #c32328;
  color: white;
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
  font-size: 75%;
  line-height: 30px;
}
.news-filter-row label .num:empty,
.news-filter-row .label .num:empty {
  display: none;
}
.news-filter-row input:checked + label {
  background: #c32328;
  color: white;
}
.news-filter-row input:checked + label .num {
  background: white;
  color: #0f0f0f;
}
.news-tile-wide {
  position: relative;
  background-color: white;
  height: 30rem;
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.25s;
}
.news-tile-wide.no-image {
  background: url(../img/news_tile_bg.jpg) no-repeat center;
  background-size: cover;
}
.news-tile-wide:hover {
  box-shadow: 6.217px 5.035px 40px 0px rgba(196, 183, 174, 0.75);
}
.news-tile-wide .img-item {
  flex-grow: 1000;
  flex-shrink: 1;
}
.news-tile-wide .img-item .img-wrap {
  text-align: right;
}
.news-tile-wide .content-item {
  flex-grow: 1;
  flex-shrink: 1000;
}
.news-tile-wide .content {
  padding: 3rem;
}
.news-tile-wide .title {
  font-size: 2.4rem;
  font-weight: 300;
  margin: 0;
  padding-left: 1.5rem;
  position: relative;
}
.news-tile-wide .title::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 5rem;
  border-left: 1px solid #e4d7cd;
}
.news-tile-wide .date-label {
  position: absolute;
  border-left: 1px solid #e4d7cd;
  padding-left: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 2.4rem;
  font-weight: 300;
  bottom: 3rem;
  left: 3rem;
}
.news-filter-row .archive-item {
  padding-top: 16px;
}
@media (min-width: 1600px) {
  .news-page-bg {
    min-height: 900px;
    background-image: url(../img/news_bg/left_top.png), url(../img/news_bg/right_bottom.png);
    background-repeat: no-repeat;
    background-position: 0 0,
      100% 100%;
  }
  .news-page-bg.tall {
    background-image: url(../img/news_bg/left_top.png), url(../img/news_bg/right_bottom.png), url(../img/news_bg/left_bottom.png), url(../img/news_bg/right_top.png);
    background-repeat: no-repeat;
    background-position: 0 0, 100% 100%, 0 calc(100% - 350px), 100% 0;
  }
}
.news-article-wrap {
  display: block;
  background: white;
  padding: 3rem;
  border-radius: 6px;
}
.news-article-wrap .rich-text-wrap {
  font-size: 2.4rem;
}
.news-article-wrap .article-title {
  color: #c32328;
  font-size: 4rem;
  font-weight: normal;
  margin: 2rem 0 4rem;
}
.news-article-wrap .video-tile {
  padding: 0;
  background: transparent;
  box-shadow: none!important;
}
.about-top-block image {
  mask: url(#aboutTopMask);
}
@media (min-width: 768px) {
  .about-content-block .rich-text-wrap,
  .about-history-block .rich-text-wrap,
  .about-bottom-block .rich-text-wrap {
    padding-left: 3rem;
  }
}
@media (max-width: 767px) {
  .about-content-block .rich-text-wrap,
  .about-history-block .rich-text-wrap,
  .about-bottom-block .rich-text-wrap {
    font-size: 2.8rem;
  }
}
.about-content-block .red-line-wrap,
.about-history-block .red-line-wrap,
.about-bottom-block .red-line-wrap {
  position: relative;
  padding-left: 2rem;
}
.about-content-block .red-line-wrap::before,
.about-history-block .red-line-wrap::before,
.about-bottom-block .red-line-wrap::before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 11rem;
  border-left: 1px solid #c32328;
}
.about-content-block {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.about-content-block .content-item {
  flex-grow: 1;
  flex-shrink: 1000;
}
.about-content-block .projects-item {
  flex-grow: 1000;
  flex-shrink: 1;
}
.about-content-block .projects-wrap {
  position: relative;
  padding-left: 3rem;
}
.about-content-block .projects-wrap ul {
  margin: 0;
  padding: 0;
  width: 140px;
}
.about-content-block .projects-wrap .projects-title {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  white-space: nowrap;
  font-size: 1.8rem;
  font-weight: 500;
  color: #c32328;
  padding-left: 1rem;
  padding-right: 1rem;
  border-top: 1px dashed #c32328;
}
@media (min-width: 768px) {
  .about-banner-wrap {
    padding-left: 5rem;
  }
}
.about-banner-wrap .img-wrap {
  border-radius: 5px;
}
.about-docs-tile {
  background: white;
  border-radius: 5px;
  padding: 2rem 2.5rem;
  position: relative;
}
.about-docs-tile .icon {
  font-size: 7rem;
  color: #c32328;
}
.about-docs-tile .file-name {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 1.143;
  transition: color 0.25s;
}
.about-docs-tile:hover .file-name {
  color: #c32328;
}
.about-docs-slider {
  margin-left: -15px;
  margin-right: -15px;
}
.about-docs-slider .slide {
  padding-left: 15px;
  padding-right: 15px;
}
.video-tile {
  background: white;
  padding: 2rem;
  border-radius: 5px;
  transition: all 0.25s;
}
.video-tile .video-sizer {
  padding-bottom: 56.25%;
}
.video-tile .preview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 1s;
}
.video-tile .preview .review-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-tile .video-start {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.6);
  transition: background 0.25s;
}
.video-tile .video-start::before {
  display: block;
  content: url(../img/play.png);
  width: 4.4rem;
  height: 5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: 0.5rem;
  transform: translate(-50%, -50%);
}
.video-tile .video-start:hover {
  background: rgba(195, 35, 40, 0.6);
}
.video-tile .video-wrapper {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 3rem;
}
.video-tile .video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-tile .video-wrapper.show .preview {
  opacity: 0;
  pointer-events: none;
}
.video-tile .video-title {
  border-left: 1px solid #b4b4b4;
  padding-left: 4rem;
  font-weight: 500;
  font-size: 2.8rem;
  transition: all 0.25s;
  max-width: 400px;
  margin-bottom: 2rem;
  line-height: 1.143;
}
.video-tile:hover {
  box-shadow: 6.217px 5.035px 40px 0px rgba(196, 183, 174, 0.75);
}
.video-tile:hover .video-title {
  color: #c32328;
  border-color: #c32328;
}
@media (min-width: 1600px) {
  .about-content-block-bg {
    min-height: 1000px;
    background-image: url(../img/about_bg/top_left.png), url(../img/about_bg/top_right.png);
    background-repeat: no-repeat;
    background-position: 0 300px,
    100% 100px;
  }
}
.about-history-block {
  padding-top: 200px;
  margin-top: -150px;
  background-image: url(../img/about_history_bg.png), linear-gradient(to bottom, transparent 1510px, #f37a1c 1511px);
  background-repeat: no-repeat;
  background-size: 1920px 1510px, 1920px auto;
  background-position: center top, center top;
}
@media (min-width: 1600px) {
  .about-history-block {
    min-height: 1510px;
    background-image: url(../img/about_bg/top_left_02.png), url(../img/about_bg/top_right_02.png), url(../img/about_history_bg.png), linear-gradient(to bottom, transparent 1510px, #f37a1c 1511px);
    background-repeat: no-repeat;
    background-size: auto auto, auto auto, 1920px 1510px, 1920px auto;
    background-position: 0 320px,
    100% 0,
    center top,
    center top;
  }
}
.about-history-block.new {
  padding-top: 50px;
  padding-bottom: 150px;
  margin-top: -50px;
  margin-bottom: -30px;
  position: relative;
  z-index: 1;
  background-image: url(../img/about_history_bg_new.png);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
}
@media (min-width: 768px) {
  .about-history-block.new {
    padding-bottom: 250px;
    margin-bottom: -70px;
  }
}
@media (min-width: 1600px) {
  .about-history-block.new {
    min-height: 1055px;
    background-image: url(../img/about_bg/top_left_02.png), url(../img/about_bg/top_right_02.png), url(../img/about_history_bg_new.png);
    background-repeat: no-repeat;
    background-size: auto auto, auto auto, 1920px 1055px;
    background-position: 0 340px,
      100% 0,
      center top,
      center top;
  }
}
.about-history-block .rich-text-wrap {
  max-width: 940px;
}
.about-bottom-block {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-image: url(../img/about_bottom_bg.jpg);
  background-repeat: no-repeat;
  background-size: 1920px auto;
  background-position: center top;
}
@media (min-width: 1600px) {
  .about-bottom-block {
    background-image: url(../img/about_bg/bottom_left_03.png), url(../img/about_bg/top_left_03.png), url(../img/about_bg/bottom_right_03.png), url(../img/about_bottom_bg.jpg);
    background-repeat: no-repeat;
    background-size: auto auto, auto auto, auto auto, 1920px auto;
    background-position: 0 100%, 0 0, 100% calc(100% - 100px), center top;
  }
}
.bottom-doc-tile {
  background-color: white;
  padding: 3rem;
  border-radius: 5px;
  position: relative;
  max-width: 300px;
}
.bottom-doc-tile .icon {
  color: #c32328;
  font-size: 7.4rem;
  margin-bottom: 2rem;
}
.bottom-doc-tile .file-name {
  font-size: 2.8rem;
  line-height: 1.143;
  font-weight: 500;
  transition: all 0.25s;
}
.bottom-doc-tile:hover .file-name {
  color: #c32328;
}
.about-bottom-block .rich-text-wrap {
  max-width: 920px;
}
.about-large-slider {
  margin-left: -15px;
  padding-right: -15px;
  margin-bottom: 3rem;
}
.about-large-slider .slide {
  padding-left: 15px;
  padding-right: 15px;
}
.about-large-slider .img-wrap {
  display: block;
  border-radius: 5px;
}
.about-small-slider {
  margin-top: -30px;
  margin-left: -13px;
  padding-right: -18px;
}
.about-small-slider .slide {
  padding-left: 13px;
  padding-right: 13px;
}
.about-small-slider .slick-list {
  padding: 30px 5px 30px 0px;
}
.about-small-slider .img-wrap {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 160px;
  height: 160px;
  background: white;
  max-width: 160px;
  border-radius: 50%;
  box-shadow: 6.217px 5.035px 40px 0px rgba(191, 165, 127, 0.75);
  padding: 10px;
}
.about-small-slider .img-wrap::before {
  display: inline-block;
  content: "";
  height: 140px;
  vertical-align: middle;
}
.slick-slider.about-small-slider .img-wrap {
  box-shadow: none;
  border: 1px solid rgba(191, 165, 127, 0.75);
}
.about-text-scroller {
  max-height: 694px;
  overflow: auto;
}
.about-text-scroller::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.about-text-scroller::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e5e6e7;
}
.about-text-scroller::-webkit-scrollbar-thumb {
  background-color: #949494;
  cursor: all-scroll;
}
.about-text-scroller::-webkit-scrollbar-thumb:hover {
  background-color: #c32328;
}
.history-text-scroller {
  max-height: 300px;
  overflow: auto;
}
.history-text-scroller::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.history-text-scroller::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e5e6e7;
}
.history-text-scroller::-webkit-scrollbar-thumb {
  background-color: #949494;
  cursor: all-scroll;
}
.history-text-scroller::-webkit-scrollbar-thumb:hover {
  background-color: #c32328;
}
@media (min-width: 768px) {
  .history-text-scroller {
    max-height: 464px;
  }
}
@media (min-width: 1600px) {
  .partners-page {
    background-image: url(../img/partners_bg/right_top.png), url(../img/partners_bg/left_top.png), url(../img/partners_bg/right_bottom.png), url(../img/partners_bg/left_bottom.png);
    background-repeat: no-repeat;
    background-position: 100% 65px, 0 50px, 100% calc(100% - 600px), 0 calc(100% - 550px);
  }
}
.vacancy-common-form-wrap {
  padding: 3rem;
  border-radius: 5px;
  background-color: #ece2dd;
}
.vacancy-common-form-wrap .form-title {
  color: #c32328;
  margin: 0;
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 2rem;
}
.vacancy-tile {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.vacancy-tile::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.vacancy-tile::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e5e6e7;
}
.vacancy-tile::-webkit-scrollbar-thumb {
  background-color: #949494;
  cursor: all-scroll;
}
.vacancy-tile::-webkit-scrollbar-thumb:hover {
  background-color: #c32328;
}
.vacancy-tile .button-wrap {
  position: relative;
}
@media (min-width: 992px) {
  .vacancy-tile .button-wrap .common-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.vacancy-tile .vacancy-main-title {
  color: #c32328;
  font-size: 35px;
  margin: 0;
  margin-bottom: 3rem;
}
.vacancy-tile-popup {
  position: relative;
  max-width: 1240px;
  margin: auto;
  background-color: white;
  border-radius: 10px;
  padding: 6rem;
}
.vacancy-contents .tab-content,
.vacancy-tile .tab-content {
  display: none;
}
.vacancy-contents .tab-content.active,
.vacancy-tile .tab-content.active {
  display: block;
}
.vacancy-contents .table thead td,
.vacancy-tile .table thead td {
  font-size: 24px;
  font-weight: normal;
  color: #c32328;
}
.vacancy-contents .table td,
.vacancy-tile .table td {
  font-size: 24px;
  line-height: 1.2;
}
.vacancy-contents .table .vacancy-title,
.vacancy-tile .table .vacancy-title {
  font-size: 24px;
  font-weight: normal;
  margin: 0;
}
.vacancy-tabs li {
  font-size: 24px;
  color: #aeaeae;
  font-weight: normal;
  cursor: pointer;
}
.vacancy-tabs li.active {
  color: #c32328;
}
.tabs-wrap {
  background-color: white;
  padding: 3rem;
  border-radius: 5px;
}
.search-block .total-wrap {
  font-weight: bold;
  font-size: 24px;
}
.search-form-wrap {
  position: relative;
}
.search-form-wrap input {
  font-weight: bold;
  font-size: 24px;
  background: white;
  border: 1px solid #e4d7cd;
  border-radius: 10000px;
  display: block;
  width: 100%;
  padding: 0.5rem 23rem 0.5rem 3rem;
}
.search-form-wrap button {
  width: 20rem;
  position: absolute;
  top: 0;
  right: 0;
  background: #c32328;
  color: white;
  border-radius: 10000px;
  border: 1px solid #c32328;
  text-transform: uppercase;
  padding: 0.7rem 2rem;
  font-size: 20px;
}
.search-tile {
  border-radius: 9px;
  transition: all 0.25s;
  padding: 1.5rem 2.5rem;
  position: relative;
}
.search-tile:hover {
  box-shadow: 0px 7px 40px 0px rgba(60, 48, 49, 0.18);
  background: white;
}
.search-tile .search-tile-title {
  font-size: 4rem;
  font-weight: normal;
  margin: 0 0 1rem;
}
.search-tile .parent {
  font-size: 2.4rem;
  font-weight: 300;
  margin-bottom: 1rem;
}
.search-tile .published {
  font-size: 18px;
  font-weight: 300;
  color: #737373;
}
.search-tile .crumbs {
  font-size: 18px;
  font-weight: 300;
  color: #737373;
}
.search-tile .crumbs a {
  color: #737373;
  text-decoration: none;
}
.search-tile .crumbs a:hover {
  color: #666666;
  text-decoration: none;
}
.search-tile .crumbs a:focus {
  color: #737373;
  text-decoration: none;
}
.search-tile .crumbs a:active,
.search-tile .crumbs a:active:focus {
  color: #5a5a5a;
  text-decoration: none;
}
.search-tile .crumbs .breadcrumb {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
  font-size: inherit;
}
.review-wrapper {
  padding: 3rem;
  padding-top: 1px;
  background: #EDE4DF;
  border-radius: 5px;
}
.review-wrapper h3 {
  font-weight: 300;
}
.review-wrapper .ec-rating {
  display: flex;
  align-items: center;
}
.ec-rating-stars span,
.ec-stars,
.ec-stars span {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAYAAAASYli2AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAtlJREFUeNqkVUFPE0EUfrPbClgSe0FNPDDS1iwk2u1VQuwmmpB4qdF4wQT6D0w8ecKjNzDx5AVM5MwREmJaKagXQ72YCkj3oFEhxgWjaaC7zzcrW7ZburvFl+zOdObN12/mffMtA58oJZWsaEc2K0W/PERs9CPgmwmThz1fQHdIAezsx2H6X4Auds39kwC62DkRmiUTrzXO43+iUW6askoD/Qwwh4ypHsY6vWeRWe8Zk4zeeq2c0XXDWxRWSgz+pKE4nCiYIUl17er6evloywxviYlOoZDWoIm54Y2NcsuWV1NDqmVhISxTAWZKTNM2PpS9W7aLMkwTEiWEYeoFa1tlAUr/kw+WBd5vB9YiG4aYDmSI/sfSrEOGarDOmBoeECXumS967zF2BHjEsIgmZEc+VTTxUBHzdL76vxyLh9LVaiqlLg8o1eXEpVy7HJqfWE4o1dWLg/1e+3KewKA7/Mg7VqCrepwnBgIKRqWEgmGMwQGUfCvKYDI+er0j+/Jl93b4Gv74XMV3t+8GsgxkKNj15cfsvt1aOBWGSKTVD9kZUm82pl7m8dEbdlJMvQKnM2m1xGAekJWP80SXHypVqTfGT50/B93JBMi9MehKDdggURpz4uDbd9hbeQ3Wr99Q29yyf4tW6NM0a5lstWoDM2FdpmXNX3j4gNsFCBnGwhJ8eTzVcB5HMrYfFhSFywdQODsxxvvy9wLBtmdewM7MnG5aoGl6RXd7ol0UrVLRzSho27Nz+g4ldwrW4tiNG0DXL2LJa0OvFtoCVm7egf3dvYymN1t/E8NGyVGOi2L4hbtQwW5jsbR7wT5VUhyBaJ3oSQ5AVJbTvjo88jrg3bTAATIWXxp0JkU6W5U0yYXAu2jeQtvmngcCMvLDvZU38PXpM4M+B9OmVXuikXCFCnYXlsaNxaWJnmSCM9beZJuKIryOIczWD4FabIuAI/swTnvJjWx9zBxXlL8CDACHpXFkySkeFgAAAABJRU5ErkJggg==') !important;
}
.review-list-wrapper .ec-message__reply {
  margin-left: 0;
  padding-left: 0;
  padding-top: 0;
  border-top: 1px solid #e4d7cd;
  margin-top: 15px;
  position: relative;
}
.review-list-wrapper .ec-message__reply::after {
  background: white;
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  border-left: 1px solid #e4d7cd;
  border-top: 1px solid #e4d7cd;
  position: absolute;
  top: -6px;
  left: 20px;
}
.review-list-wrapper .ec-message__reply .reply {
  padding-left: 60px;
}
.review-list-wrapper .ec-message {
  background: white;
  border: none;
  height: 100%;
  padding: 2rem 4rem;
}
.review-list-wrapper .ec-message__meta {
  margin: 0;
  line-height: 1;
}
.review-list-wrapper .ec-message__meta .ec-stars {
  float: none;
  margin-left: auto;
}
.review-list-wrapper .ec-message__meta .ec-message__author {
  position: relative;
  margin: 0;
  margin-bottom: 2rem;
  margin-left: -1.5rem;
  padding-left: 1.5rem;
  float: none;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0;
  text-align: left;
  min-height: 5rem;
  color: #c32328;
}
.review-list-wrapper .ec-message__meta .ec-message__author::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 5rem;
  border-left: 1px solid #e4d7cd;
  content: "";
}
.ec-message-popup {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 970px;
}
.ec-message-popup .ec-message {
  padding-top: 6rem;
}
@media (min-width: 1024px) {
  .ec-message-popup .ec-message {
    padding-left: 5rem ;
    padding-right: 5rem;
    padding-bottom: 5rem;
  }
}
