.clearfix() {
  &::after,
  &::before {
    display: table;
    content: "";
  }

  &::after {
    clear: both;
  }
}

.link-colored(@color, @td) {
  a {
    color: @color;
    text-decoration: @td;

    &:hover {
      color: darken(@color, 5%);
      text-decoration: none;
    }
    &:focus {
      color: @color;
      text-decoration: @td;
    }
    &:active,
    &:active:focus {
      color: darken(@color, 10%);
      text-decoration: none;
    }
  }
}

.webkit-scrollbar() {
  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: @gray;
  }
  &::-webkit-scrollbar-thumb {
    background-color: @gray_text;
    cursor: all-scroll;
    &:hover {
      background-color: @red;
    }
  }
}

