.popup {
  position: relative;
  max-width: 1240px;
  margin: auto;

  padding: 3rem;
  border-radius: 5px;
  background-color: #ece2dd;
  .form-title {
    color: @red;
    margin: 0;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 2rem;
  }
}
