ul, ol {
  margin-top: 0;
  margin-bottom: 10px
}

ul ul, ol ul, ul ol, ol ol {
  margin-bottom: 0
}

.list-unstyled {
  padding-left: 0;
  list-style: none
}

.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px
}

.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px
}
