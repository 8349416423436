.news-filter-row {
  margin-bottom: 3.5rem;
  fieldset {
    margin: 0;
    padding: 0;
    border: none;
  }
  .filter-btn {
    display: block;
    background: @disable;
    color: @black_text;
    line-height: 1;
    padding: 21px 0 18px;
    border-radius: 50%;
    margin-bottom: 1rem;
    font-size: 20px;
    text-align: center;
    width: 84px;
    height: 84px;
    text-decoration: none!important;
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: @black_text;
    }
    &.hidden {
      background: @red;
      display: block !important;
      color: white;
      &:hover,
      &:focus,
      &:active,
      &:active:focus {
        color: white;
      }
    }
  }
  label,
  .label {
    display: block;
    font-weight: 300;
    background: @disable;
    color: @black_text;
    line-height: 1;
    padding: 21px 0 18px;
    border-radius: 50%;
    margin-bottom: 1rem;
    position: relative;
    font-size: 20px;
    text-align: center;
    width: 84px;
    height: 84px;
    text-decoration: none!important;
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: @black_text;
    }
    .num {
      position: absolute;
      top: -10px;
      right: -5px;
      background: @red;
      color: white;
      display: block;
      width: 30px;
      height: 30px;
      text-align: center;
      border-radius: 50%;
      font-size: 75%;
      line-height: 30px;
      &:empty {
        display: none;
      }
    }
  }
  input:checked + label {
    background: @red;
    color: white;

    .num {
      background: white;
      color: @black_text;
    }
  }
}

.news-tile-wide {
  position: relative;
  background-color: white;
  height: 30rem;
  border-radius: 5px;
  overflow: hidden;
  transition: all .25s;
  &.no-image {
    background: url(../img/news_tile_bg.jpg) no-repeat center;
    background-size: cover;
  }
  &:hover {
    box-shadow: 6.217px 5.035px 40px 0px rgba(196, 183, 174, 0.75);
  }
  .img-item {
    flex-grow: 1000;
    flex-shrink: 1;
    .img-wrap {
      text-align: right;
    }
  }
  .content-item {
    flex-grow: 1;
    flex-shrink: 1000;
  }
  .content {
    padding: 3rem;
  }
  .title {
    font-size: 2.4rem;
    font-weight: 300;
    margin: 0;
    padding-left: 1.5rem;
    position: relative;
    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 5rem;
      border-left: 1px solid @disable;
    }
  }

  .date-label {
    position: absolute;
    border-left: 1px solid @disable;
    padding-left: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 2.4rem;
    font-weight: 300;
    bottom: 3rem;
    left: 3rem;
  }
}

.news-filter-row {
  .archive-item {
    padding-top: 16px;
  }
}

.news-page-bg {
  @media (min-width: 1600px) {
    min-height: 900px;
    background-image:
      url(../img/news_bg/left_top.png),
      url(../img/news_bg/right_bottom.png);
    background-repeat: no-repeat;
    background-position:
      0 0,
      100% 100%;
    &.tall {
      background-image:
        url(../img/news_bg/left_top.png),
        url(../img/news_bg/right_bottom.png),
        url(../img/news_bg/left_bottom.png),
        url(../img/news_bg/right_top.png);
      background-repeat: no-repeat;
      background-position:
        0 0,
        100% 100%,
        0 calc(~"100% - 350px"),
        100% 0;
    }
  }

}


.news-article-wrap {
  display: block;
  background: white;
  padding: 3rem;
  border-radius: 6px;
  .rich-text-wrap {
    font-size: 2.4rem;
  }
  .article-title {
    color: @red;
    font-size: 4rem;
    font-weight: normal;
    margin: 2rem 0 4rem;
  }

  .video-tile {
    padding: 0;
    background: transparent;
    box-shadow: none!important;
  }
}
