.about-top-block {
  image {
    mask: url(#aboutTopMask)
  }
}

.about-content-block,
.about-history-block,
.about-bottom-block {
  .rich-text-wrap {
    @media (min-width: 768px) {
      padding-left: 3rem;
    }
    @media (max-width: 767px) {
      font-size: 2.8rem;
    }
  }
  .red-line-wrap {
    position: relative;
    padding-left: 2rem;
    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 11rem;
      border-left: 1px solid @red;
    }
  }
}

.about-content-block {
  padding-top: 3rem;
  padding-bottom: 3rem;
  .content-item {
    flex-grow: 1;
    flex-shrink: 1000;
  }
  .projects-item {
    flex-grow: 1000;
    flex-shrink: 1;

  }

  .projects-wrap {
    position: relative;
    padding-left: 3rem;
    ul {
      margin: 0;
      padding: 0;
      width: 140px;
    }
    .projects-title {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
      white-space: nowrap;
      font-size: 1.8rem;
      font-weight: 500;
      color: @red;
      padding-left: 1rem;
      padding-right: 1rem;
      border-top: 1px dashed @red;

    }
  }
}

.about-banner-wrap {
  @media (min-width: 768px) {
    padding-left: 5rem;
  }

  .img-wrap {
    border-radius: 5px;
  }
}

.about-docs-tile {
  background: white;
  border-radius: 5px;
  padding: 2rem 2.5rem;
  position: relative;
  .icon {
    font-size: 7rem;
    color: @red;
  }
  .file-name {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 2.8rem;
    line-height: 1.143;
    transition: color .25s;
  }
  &:hover {
    .file-name {
      color: @red;
    }
  }

}

.about-docs-slider {
  margin-left: -15px;
  margin-right: -15px;
  .slide {
    padding-left: 15px;
    padding-right: 15px;

  }
}

.video-tile {
  background: white;
  padding: 2rem;
  border-radius: 5px;
  transition: all .25s;

  .video-sizer {
    padding-bottom: 56.25%;
  }

  .preview {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 1s;
    .review-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .video-start {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 12.5rem;
    height: 12.5rem;
    border-radius: 50%;
    background: fade(white, 60%);
    transition: background .25s;
    &::before {
      display: block;
      content: url(../img/play.png);
      width: 4.4rem;
      height: 5rem;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: .5rem;
      transform: translate(-50%, -50%);
    }
    &:hover {
      background: fade(@red, 60%);
    }
  }
  .video-wrapper {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 3rem;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &.show {
      .preview {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
  .video-title {
    border-left: 1px solid #b4b4b4;
    padding-left: 4rem;
    font-weight: 500;
    font-size: 2.8rem;
    transition: all .25s;
    max-width: 400px;
    margin-bottom: 2rem;
    line-height: 1.143;

  }

  &:hover {
    box-shadow: 6.217px 5.035px 40px 0px rgba(196, 183, 174, 0.75);
    .video-title {
      color: @red;
      border-color: @red;

    }
  }
}

.about-content-block-bg {
  @media (min-width: 1600px) {
    min-height: 1000px;
    background-image: url(../img/about_bg/top_left.png),
    url(../img/about_bg/top_right.png);
    background-repeat: no-repeat;
    background-position: 0 300px,
    100% 100px;

  }
}

.about-history-block {
  padding-top: 200px;
  margin-top: -150px;
  background-image: url(../img/about_history_bg.png), linear-gradient(to bottom, transparent 1510px, #f37a1c 1511px);
  background-repeat: no-repeat;
  background-size: 1920px 1510px, 1920px auto;
  background-position: center top, center top;
  @media (min-width: 1600px) {
    min-height: 1510px;
    background-image: url(../img/about_bg/top_left_02.png),
    url(../img/about_bg/top_right_02.png),
    url(../img/about_history_bg.png),
    linear-gradient(to bottom, transparent 1510px, #f37a1c 1511px);
    background-repeat: no-repeat;
    background-size: auto auto, auto auto, 1920px 1510px, 1920px auto;
    background-position: 0 320px,
    100% 0,
    center top,
    center top;

  }

  &.new {
    padding-top: 50px;
    padding-bottom: 150px;
    margin-top: -50px;
    margin-bottom: -30px;
    position: relative;
    z-index: 1;
    background-image: url(../img/about_history_bg_new.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    @media (min-width: 768px) {
      padding-bottom: 250px;
      margin-bottom: -70px;
    }
    @media (min-width: 1600px) {
      min-height: 1055px;
      background-image: url(../img/about_bg/top_left_02.png),
      url(../img/about_bg/top_right_02.png),
      url(../img/about_history_bg_new.png);
      background-repeat: no-repeat;
      background-size: auto auto, auto auto, 1920px 1055px;
      background-position: 0 340px,
      100% 0,
      center top,
      center top;

    }
  }

}

.about-history-block {
  .rich-text-wrap {
    max-width: 940px;
  }
}

.about-bottom-block {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-image: url(../img/about_bottom_bg.jpg);
  background-repeat: no-repeat;
  background-size: 1920px auto;
  background-position: center top;
  @media (min-width: 1600px) {
    background-image: url(../img/about_bg/bottom_left_03.png),
    url(../img/about_bg/top_left_03.png),
    url(../img/about_bg/bottom_right_03.png),
    url(../img/about_bottom_bg.jpg);
    background-repeat: no-repeat;
    background-size: auto auto, auto auto, auto auto, 1920px auto;
    background-position: 0 100%,
    0 0,
    100% calc(~"100% - 100px"),
    center top;
  }
}

.bottom-doc-tile {
  background-color: white;
  padding: 3rem;
  border-radius: 5px;
  position: relative;
  max-width: 300px;
  .icon {
    color: @red;
    font-size: 7.4rem;
    margin-bottom: 2rem;
  }
  .file-name {
    font-size: 2.8rem;
    line-height: 1.143;
    font-weight: 500;
    transition: all .25s;
  }
  &:hover {
    .file-name {
      color: @red;
    }
  }
}

.about-bottom-block {
  .rich-text-wrap {
    max-width: 920px;
  }

}

.about-large-slider {
  margin-left: -15px;
  padding-right: -15px;
  margin-bottom: 3rem;
  .slide {
    padding-left: 15px;
    padding-right: 15px;
  }
  .img-wrap {
    display: block;
    border-radius: 5px;
  }
}

.about-small-slider {
  margin-top: -30px;
  margin-left: -13px;
  padding-right: -18px;

  .slide {
    padding-left: 13px;
    padding-right: 13px;
  }
  .slick-list {
    padding: 30px 5px 30px 0px;
  }
  .img-wrap {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 160px;
    height: 160px;
    background: white;
    max-width: 160px;
    border-radius: 50%;
    box-shadow: 6.217px 5.035px 40px 0px rgba(191, 165, 127, 0.75);
    padding: 10px;
    &::before {
      display: inline-block;
      content: "";
      height: 140px;
      vertical-align: middle;
    }
  }
}

.slick-slider.about-small-slider {
  .img-wrap {
    box-shadow: none;
    border: 1px solid rgba(191, 165, 127, 0.75);
  }
}

.about-text-scroller {
  max-height: 694px;
  overflow: auto;
  .webkit-scrollbar();

}

.history-text-scroller {
  max-height: 300px;
  overflow: auto;
  .webkit-scrollbar();
  @media (min-width: 768px) {
    max-height: 464px;
  }
}

