.btn {
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  vertical-align: top;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }
}

.transparent-btn {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;

  .dotted {
    font-size: 16px;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    &::after {
      display: block;
      content: '...........................................................................................................';
      position: absolute;
      width: 100%;
      overflow: hidden;
      left: 0;
      bottom: -.45em;
      transition: color .25s;
    }
  }
  &:hover {
    text-decoration: none;
    .dotted {
      &::after {
        color: transparent;
      }
    }
  }
}

.common-btn {
  display: inline-block;
  padding: 15px 40px;
  //transition: all .25s;
  position: relative;
  background-color: @disable;
  border: 1px solid @disable;
  color: @black_text;
  white-space: nowrap;
  transition: box-shadow .25s;
  line-height: 1;
  text-decoration: none;
  border-radius: 26px;
  //@media (min-width: 768px) {
  //  padding: 14px 20px;
  //}
  .text {
    text-align: center;
    font-size: 20px;
    display: block;
    font-weight: normal;
    line-height: 1;
    pointer-events: none;
    vertical-align: middle;
    //white-space: nowrap;
    color: inherit;
    text-transform: uppercase;

  }
  strong {
    font-weight: bold;
  }

  &:hover {
    text-decoration: none;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
    //box-shadow: 0 0 0 0 transparent;
    color: @black_text;
  }
  &:focus {
    text-decoration: none;
    box-shadow: 0 0 0 0 transparent;
    color: @black_text;

  }
  &:active {
    box-shadow: 0 0 0 0 transparent;
    top: 1px;
    color: @black_text;
  }
  &:active:focus {
    box-shadow: 0 0 0 0 transparent;
    top: 1px;
    color: @black_text;
  }
}

.btn-block {
  display: block;
}



.close-btn,
.mfp-close-btn-in .mfp-close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 44px;
  height: 44px;
  line-height: 44px;
  font-style: normal;
  font-size: 28px;
  border-radius: 50%;
  font-family: Arial, Baskerville, monospace;
  color: @red;
  background-color: white;
  opacity: 1;
  z-index: 10;
  text-align: center;
  padding: 0;
  //&:hover {
  //  background-color: white;
  //}
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    color: @red;
  }
}

.mfp-close-btn-in .mfp-close {
  top: 0;
  right: 0;
  @media (min-width: 768px) {
    top: 15px;
    right: 15px;
  }
}

.red-btn {
  background: @red;
  border-color: @red;
  color: white;
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    color: white;
  }

}

.main-btn {
  background: transparent;
  border-color: @orange;
  border-radius: 2px;
  padding: 0 0 0 2.8rem;
  transition: all .25s;
  &::before {
    display: block;
    content: "";
    width: 3px;
    height: 50%;
    border-radius: 2px;
    background: @orange;
    position: absolute;
    left: -2px;
    top: 50%;
    transform: translateY(-50%);
  }
  //@media (min-width: 768px) {
  //  padding-left: 50px;
  //  padding-right: 50px;
  //}
  .text {
    display: block;
    background: @orange;
    padding: 1.4rem 2rem;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2rem;
    color: white;
    line-height: 1;
    text-align: center;
    transition: all .25s;
    &.text-left {
      text-align: left;
    }
  }
  &:hover {
    padding-left: 0;
    .text {
      padding-left: 3.1em;
      padding-right: 3.1rem;
    }
  }



}

.wide-btn {
  .text {
    padding: 1.4rem 4rem;
  }
  &:hover {
    .text {
      padding-left: 5.4rem;
      padding-right: 5.4rem;
    }
  }
}

.menu-btn {
  background: @orange;
  border-color: @orange;
  color: white;
  border-radius: 3px;
}
