.partners-page {
  @media (min-width: 1600px) {
    background-image: url(../img/partners_bg/right_top.png),
    url(../img/partners_bg/left_top.png),
    url(../img/partners_bg/right_bottom.png),
    url(../img/partners_bg/left_bottom.png);
    background-repeat: no-repeat;
    background-position: 100% 65px,
    0 50px,
    100% calc(~"100% - 600px"),
    0 calc(~"100% - 550px");
  }
}
