.header {
  background-color: @red;
  color: white;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  position: sticky;
  top: 0;
  z-index: 500;
  .link-colored(white, none);

  .col-phone {
    font-size: 18px;
    white-space: nowrap;
    span {
      font-size: 1.666666666666667em;
      font-weight: 500;
    }
  }
  .form-wrap {
    position: relative;
    max-width: 116px;
    min-width: 116px;
    transition: all .25s;
    button {
      position: absolute;
      right: 0;
      top: 3px;
      background-color: transparent;
      border: none;
      font-size: 18px;
    }
    input {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid white;
      padding-left: 12px;
      padding-right: 30px;
      width: 100%;
      padding-bottom: 5px;
      &::placeholder {
        font-weight: normal;
        font-size: 16px;
        text-transform: uppercase;
        color: white;
        opacity: 1;
      }
      &::-moz-placeholder {
        font-weight: normal;
        font-size: 16px;
        text-transform: uppercase;
        color: white;
        opacity: 1;
      }
      &::-ms-input-placeholder {
        font-weight: normal;
        font-size: 16px;
        text-transform: uppercase;
        color: white;
        opacity: 1;
      }
      &::-webkit-input-placeholder {
        font-weight: normal;
        font-size: 16px;
        text-transform: uppercase;
        color: white;
        opacity: 1;

      }
      &:focus {
        &::placeholder {
          opacity: 0;
        }
        &::-moz-placeholder {
          opacity: 0;
        }
        &::-ms-input-placeholder {
          opacity: 0;
        }
        &::-webkit-input-placeholder {
          opacity: 0;

        }

      }
    }
  }
  .main-nav {
    ul:not(.lang-list) {
      margin: 0 -15px;
      display: flex;
      align-items: center;
      a {
        text-transform: uppercase;
        font-size: 20px;
        position: relative;
        display: block;
        border-radius: 23px;
        height: 45px;
        line-height: 43px;
        padding-left: 15px;
        padding-right: 15px;
        border: 1px solid transparent;
        white-space: nowrap;
        .total {
          position: absolute;
          display: block;
          background-color: white;
          color: @red;
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          font-size: .75em;
          border-radius: 50%;
          top: -15px;
          right: -15px;
        }
      }
      li {
        //padding-left: 15px;
        //padding-right: 15px;
        &.active {
          a {
            border-color: white;
          }
        }
      }
    }
  }
  .col-logo {
    max-width: 245px;
    transition: all .25s;
  }

  &.sticked {
    .col-logo {
      max-width: 110px;
    }
    .form-wrap {
      max-width: 150px;
    }
  }
  @media (max-width: 991px) {
    .col-logo {
      order: 1;
      max-width: 110px;
    }
    .col-non-logo {
      order: 2;
    }

    .col-menu {
      order: 4;
      overflow: visible;
      .main-nav {
        position: fixed;
        top: 75px;
        left: 0;
        width: 100vw;
        height: calc(~"100vh - 75px");
        background-color: @red;
        transform: translateX(100%);
        transition: all .4s;
        padding: 30px;
        &.main-nav-open {
          transform: translateX(0%);
        }
        ul {
          display: block;
          a {
            display: inline-block;
          }
        }
      }

    }

    .col-search {
      order: 2;
      max-width: 300px !important;
    }

    .col-phone {
      order: 3;
    }
  }
  @media (max-width: 767px) {
    //overflow-x: hidden;
    //overflow-y: visible;
    .col-menu {
      .main-nav {
        top: 57px;
        height: calc(~"100vh - 57px");
      }
    }
  }
}

//html:not(.ios) {
//  .header {
//    @media (max-width: 767px) {
//      overflow: hidden;
//    }
//  }
//}

.lang-list {
  text-align: right;
  a {
   opacity: .75;
  }
  a.active {
    opacity: 1;
  }
}


.header  {
  .row-top {
    max-height: 28px;
    transition: all .25s;
  }
  &.sticked {
    .row-top {
      margin-top: -10px;
      max-height: 0;
      opacity: 0;
      pointer-events: none;
    }
  }
}
