.slider-block {
  > .slide ~ .slide {
    display: none;
  }
}

.slick-dots {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  font-size: 0;
  button {
    border: none;
    padding: 0;
    background: white;
    width: 7.7rem;
    height: 3px;
    border-radius: 3px;
    transition: background .75s;
  }
  li.slick-active {
    button {
      background: @red;
    }
  }
  li ~ li {
    margin-left: 2.3rem;
  }

}

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  margin: 0;
  display: block;
  width: 4.5rem;
  height: 4.5rem;
  background: @disable;
  border: none;
  border-radius: 50%;
  z-index: 1;
  transition: background .25s;
  &:hover {
    background: @red;
  }
  span {
    display: block;
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -.5rem;
    margin-left: -.5rem;
    transform: rotate(-45deg);
  }
  &.slick-prev {
    left: -1rem;
    @media (max-width: 767px) {
      left: 1rem;
    }
    span {
      border-left: 2px solid white;
      border-top: 2px solid white;
    }
  }
  &.slick-next {
    right: -1rem;
    @media (max-width: 767px) {
      right: 1rem;
    }
    span {
      border-right: 2px solid white;
      border-bottom: 2px solid white;
    }
  }
}
