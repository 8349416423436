/*=====================
  Базовая типография
=====================*/

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-BoldCnIt'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCnIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCnIt.woff2') format('woff2'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCnIt.woff') format('woff'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCnIt.ttf') format('truetype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCnIt.svg#AkzidenzGroteskPro-BoldCnIt') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-MdCn'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCn.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCn.woff2') format('woff2'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCn.woff') format('woff'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCn.ttf') format('truetype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCn.svg#AkzidenzGroteskPro-MdCn') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-XBdCn'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCn.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCn.woff2') format('woff2'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCn.woff') format('woff'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCn.ttf') format('truetype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCn.svg#AkzidenzGroteskPro-XBdCn') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-XBdCnIt'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCnIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCnIt.woff2') format('woff2'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCnIt.woff') format('woff'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCnIt.ttf') format('truetype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-XBdCnIt.svg#AkzidenzGroteskPro-XBdCnIt') format('svg');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-BoldCn'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCn.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCn.woff2') format('woff2'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCn.woff') format('woff'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCn.ttf') format('truetype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-BoldCn.svg#AkzidenzGroteskPro-BoldCn') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-LightCnItalic'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCnItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCnItalic.woff2') format('woff2'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCnItalic.woff') format('woff'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCnItalic.ttf') format('truetype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCnItalic.svg#AkzidenzGroteskPro-LightCnItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-LightCn'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCn.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCn.woff2') format('woff2'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCn.woff') format('woff'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCn.ttf') format('truetype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-LightCn.svg#AkzidenzGroteskPro-LightCn') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-Cn'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Cn.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Cn.woff2') format('woff2'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Cn.woff') format('woff'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Cn.ttf') format('truetype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-Cn.svg#AkzidenzGroteskPro-Cn') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-MdCnIt'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCnIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCnIt.woff2') format('woff2'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCnIt.woff') format('woff'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCnIt.ttf') format('truetype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-MdCnIt.svg#AkzidenzGroteskPro-MdCnIt') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Akzidenz-Grotesk Pro Cnd';
  src: local('AkzidenzGroteskPro-CnIt'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-CnIt.eot?#iefix') format('embedded-opentype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-CnIt.woff2') format('woff2'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-CnIt.woff') format('woff'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-CnIt.ttf') format('truetype'),
  url('../fonts/AkzidenzGroteskPro/AkzidenzGroteskPro-CnIt.svg#AkzidenzGroteskPro-CnIt') format('svg');
  font-weight: normal;
  font-style: italic;
}

@base-font: 'Akzidenz-Grotesk Pro Cnd', Arial, sans-serif;

body {
  font-family: @base-font;
  line-height: 1.42857143;
  font-size: 2rem;
  font-weight: 300;
  color: @black_text;
  * {
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

}

button,
input,
optgroup,
select,
textarea {
  font-family: @base-font;
}

a {
  color: @red_text;
  text-decoration: underline;
  &:hover {
    color: darken(@red_text, 5%);
    text-decoration: none;
  }
  &:focus {
    color: @red_text;
    text-decoration: underline;
    outline: none;
  }
  &:active,
  &:active:focus {
    color: darken(@red_text, 10%);
    text-decoration: none;
  }
}


h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #777
}

h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 2rem;
  margin-bottom: 1rem
}

h1 small, .h1 small, h2 small, .h2 small, h3 small, .h3 small, h1 .small, .h1 .small, h2 .small, .h2 .small, h3 .small, .h3 .small {
  font-size: 65%
}

h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

h4 small, .h4 small, h5 small, .h5 small, h6 small, .h6 small, h4 .small, .h4 .small, h5 .small, .h5 .small, h6 .small, .h6 .small {
  font-size: 75%
}

h1, .h1 {
  font-size: 3.6rem
}

h2, .h2 {
  font-size: 3.0rem
}

h3, .h3 {
  font-size: 2.4rem
}

h4, .h4 {
  font-size: 1.8rem
}

h5, .h5 {
  font-size: 1.4rem
}

h6, .h6 {
  font-size: 1.2rem
}

p {
  margin: 0 0 1rem;
}

blockquote {
  border-color: @orange;
  font-size: 1.6rem;
}

hr {
  border-color: @orange;
}

  /********************************************/

.ttu {
  text-transform: uppercase;
}

.h-100 {
  height: 100%;
}

.mt-auto {
  margin-top: auto;
}

.mr-10 {
  margin-right: 10px;
}

ul:not(.list-unstyled):not(.list-inline) {
  padding: 0;
  li {
    list-style: none;
    padding-left: .5em;
    position: relative;
    margin-bottom: .25em;

    &::before {
      display: block;
      content: "";
      width: 0.2em;
      height: 0.2em;
      background: currentColor;
      position: absolute;
      left: 0;
      top: .6em;
    }
  }
}

//ol:not(.list-unstyled):not(.list-inline) {
//  padding: 0;
//  li {
//    list-style: none;
//    padding-left: 2.5em;
//    position: relative;
//    margin-bottom: 1em;
//    counter-increment: inst;
//    min-height: 3.075em;
//    padding-top: 0.45em;
//    &::before {
//      display: block;
//      content: counter(inst);
//      font-size: 0.6666666666666667em;
//      font-weight: 300;
//      width: 2.75em;
//      height: 2.75em;
//      line-height: 2.85em;
//      text-align: center;
//      border-radius: 50%;
//      background: @blue;
//      color: white;
//      position: absolute;
//      left: 0;
//      top: 0;
//    }
//  }
//}

//blockquote {
//  border: 1px solid @red;
//  position: relative;
//  padding: 20px 30px 20px 48px;
//  font-size: 16px;
//  margin-left: 22px;
//  @media (min-width: 768px) {
//    padding: 40px 70px;
//  }
//  &::before {
//    display: block;
//    content: "";
//    position: absolute;
//    box-sizing: content-box;
//    left: -22px;
//    top: 50%;
//    transform: translateY(-50%);
//    width: 25px;
//    height: 25px;
//    border: 10px solid @red;
//    background: white;
//  }
//  strong {
//
//    line-height: 1.2;
//    font-size: 16px;
//    @media (min-width: 768px) {
//      font-size: 30px;
//    }
//    @media (min-width: 992px) {
//      font-size: 36px;
//    }
//  }
//}
//
//.rich-text-content {
//  font-size: 18px;
//  p {
//    margin-bottom: 1.2em;
//  }
//  h3 {
//    text-transform: uppercase;
//    font-size: 22px;
//    font-weight: bold;
//    &:not(:first-child) {
//      margin-top: 30px;
//    }
//  }
//
//  ul:not(.list-unstyled):not(.list-inline) {
//
//    li {
//      &::before {
//        border-radius: 50%;
//      }
//    }
//  }
//  &.vacancy-mod {
//    p {
//      margin-bottom: .7em;
//    }
//  }
//
//}
