.vacancy-common-form-wrap {
  padding: 3rem;
  border-radius: 5px;
  background-color: #ece2dd;
  .form-title {
    color: @red;
    margin: 0;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 2rem;
  }
}

.vacancy-tile {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  .webkit-scrollbar();
  .button-wrap {
    position: relative;
    @media (min-width: 992px) {
      .common-btn {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
  .vacancy-main-title {
    color: @red;
    font-size: 35px;
    margin: 0;
    margin-bottom: 3rem;
  }
}

.vacancy-tile-popup {
  position: relative;
  max-width: 1240px;
  margin: auto;
  background-color: white;
  border-radius: 10px;
  padding: 6rem;
}

.vacancy-contents,
.vacancy-tile {
  .tab-content {
    display: none;
    &.active {
      display: block;
    }
  }
  .table {
    thead td {
      font-size: 24px;
      font-weight: normal;
      color: @red;
    }
    td {
      font-size: 24px;
      line-height: 1.2;
    }
    .vacancy-title {
      font-size: 24px;
      font-weight: normal;
      margin: 0;
    }
  }
}

.vacancy-tabs {
  li {
    font-size: 24px;
    color: #aeaeae;
    font-weight: normal;
    cursor: pointer;
    &.active {
      color: @red
    }
  }
}

.tabs-wrap {
  background-color: white;
  padding: 3rem;
  border-radius: 5px;



}
