.wellcome-block {
  image {
    mask: url(#wellcomeMask)
  }
}

.wheat-block {
  background: #ffeeaf url(../img/wheat_bg.jpg) no-repeat center top;
  background-size: 1920px auto;
  @media (min-width: 1920px) {
    background-size: 100% auto;
  }
}

