input[type="text"],
textarea,
select,
input[type="password"] {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  font-family: @base-font;
  font-size: 18px;
  line-height: 20px;
}

label {
  cursor: pointer;
}

button {
  outline: none;
  cursor: pointer;
}

input:focus {
  outline: none;
}

input::-webkit-input-placeholder {
  opacity: 0.8;
}

input::-moz-placeholder {
  opacity: 0.8;
}

select:not(.unstyled) {
  display: none;
}

.jq-number {
  display: block;
}

.error-bubble {
  position: relative;
  display: block;
  .error {
    position: absolute;
    display: block;
    width: 300px;
    bottom: 100%;
    left: -5px;
    transform: translateY(-100%);
    background: white;
    border: 1px solid @red;
    color: @red;
    padding: 5px 10px;
    box-shadow: 2px 2px 0 fade(black, 25%);
    pointer-events: none;
  }
}

.form-control,
.jq-number input,
.form-control-div {
  display: block;
  width: 100%;
  padding: 1.5rem 4rem;
  height: 6.6rem;
  border: 1px solid white;
  background-color: white;
  border-radius: 3.3rem;
  box-shadow: none;
  font-size: 3rem;
  @media (min-width: 768px) {
    padding: 1.5rem 4rem;
    height: 4.4rem;
    border-radius: 2.2rem;
    font-size: 2rem;
  }
  &:focus {
    border-color: @orange;
    //box-shadow: inset 0 1px 1px fade(black, 7.5%), 0 0 8px fade(@red, 60%);
    box-shadow: none;
  }
  &::placeholder {
    font-weight: 300;
    font-size: 3rem;
    opacity: 1;
    color: @black_text;
  }
  &::-moz-placeholder {
    font-weight: 300;
    font-size: 3rem;
    opacity: 1;
    color: @black_text;
  }
  &::-ms-input-placeholder {
    font-weight: 300;
    font-size: 3rem;
    opacity: 1;
    color: @black_text;
  }
  &::-webkit-input-placeholder {
    font-weight: 300;
    font-size: 3rem;
    opacity: 1;
    color: @black_text;
  }
  @media (min-width: 768px) {
    &::placeholder {
      font-size: 2rem;
    }
    &::-moz-placeholder {
      font-size: 2rem;
    }
    &::-ms-input-placeholder {
      font-size: 2rem;
    }
    &::-webkit-input-placeholder {
      font-size: 2rem;
    }
  }
  &.error {
    border-color: @red!important;
  }
}

textarea.form-control {
  .webkit-scrollbar();
}

.jq-selectbox__select {
  padding: 0 35px 0 13px;
  border-radius: 3px;
  font: 16px/34px @base-font;
  color: #a7a7a7;
  border: 1px solid #b8b8b8;
  //background-color: #f5f5f5;
  background: white;
  @media (min-width: 768px) {
    font: 16px/50px @base-font;
  }
}

.opened {
  .jq-selectbox__select {
    border-color: @red;
    //box-shadow: inset 0 1px 1px fade(black, 7.5%), 0 0 8px fade(@red, 60%);
    box-shadow: none;
  }
}

.jq-selectbox__select-text {
  display: block;
  width: 100% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  font-size: 16px;
  &.placeholder {
    color: #a7a7a7;
  }
  //span {
  //  display: block;
  //  height: 50px;
  //}
}

.jq-selectbox__trigger {
  position: absolute;
  top: 0;
  right: 0;
  width: 34px;
  height: 100%;
  //border-left: 1px solid #CCC;
  font-size: 24px;
  color: #999;
}

.jq-selectbox__trigger-arrow {
  position: absolute;
  top: 50%;
  margin-top: -18px;
  right: 9px;
  display: inline-block;
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
  font-family: Arial, Baskerville, monospace;
  color: @red;
  font-size: 16px;
  cursor: pointer;
  transition: transform .25s, margin .25s;
  transform: scale(1, .8);
  &::before {
    content: "▼";
  }
  @media (min-width: 768px) {
    margin-top: -25px;
  }
}

.opened {
  .jq-selectbox__trigger-arrow {
    transform: scale(1, -.8);
    margin-top: -20px;
    @media (min-width: 768px) {
      margin-top: -27px;
    }
  }
}

.jq-selectbox__dropdown {
  box-sizing: border-box;
  width: 100%;
  margin: 2px 0 0;
  padding: 0;
  border: 1px solid #CCC;
  //border-radius: 4px;
  background: #FFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
  font: 14px/18px @base-font;
  text-align: left;
  //max-height: 50vh;
  min-width: 100%;
  ul {
    max-height: 50vh;
    overflow: auto;
    .webkit-scrollbar();
  }
}

.jq-selectbox__search {
  margin: 5px;

  input {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 5px 27px 6px 8px;
    border: 1px solid #CCC;
    border-radius: 0;
    outline: none;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO1JREFUeNqU078LAXEYx/FzYfNzk5TJaFNKYjFYSQZ/hvwBsvg1UCY2xT9gM8hukQGThWRjkcFw3pdnujhfT736Xn2fPvfc3fd07V0OFDDFAnM0ENYsZRiGLSc9OpqIYIA9fMhhjCrW2h9VlMlcH/aymMGtOqEugX08PwQucUZKdTozMIqdTc9WepQD7wjY9ARx+ydwhfyXfS+S0qMcOEQJGcueB3VccFINdMgal6NzkmPjRwJXxDBB7/2RDdtAp6wb+dpphHDASG5QQ0V6u2aoSqBZD/lDrNWRJynLK2qpBn4rc6K2XB9/Nb8EGABtf1thzY6X2AAAAABJRU5ErkJggg==') no-repeat 100% 50%;
    box-shadow: inset 1px 1px #F1F1F1;
    color: #333;
  }
}

.jq-selectbox__not-found {
  margin: 5px;
  padding: 5px 8px 6px;
  background: #F0F0F0;
  font-size: 13px;
}

.jq-selectbox ul {
  margin: 0;
  padding: 0;
  border-radius: 0;
}

.jq-selectbox li {
  //min-height: 18px;
  padding: 7px 10px 5px !important;
  color: @black_text;
  margin-bottom: 0 !important;
  //padding-left: 10px !important;
  //margin-bottom: 0 !important;
  //&::before {
  //  display: none !important;
  //}
  &::before {
    display: none !important;
  }
  &.selected {
    background-color: @gray;
    color: @black_text;
  }
  &:hover {
    background-color: @red;
    color: white;
  }
  &.disabled {
    color: #AAA;
    &:hover {
      background: none;
    }
  }
  &.optgroup {
    font-weight: bold;
    &:hover {
      background: none;
      color: @black_text;
      cursor: default;
    }
  }
  &.option {
    padding-left: 25px;
  }

}

//.jq-selectbox__select {
//  background-color: white;
//  border-radius: 3px;
//  border: 1px solid white;
//  font: 12px/32px @base-font;
//  padding: 0 34px 0 10px;
//}

//.jq-selectbox__dropdown {
//  font: 12px/16px @base-font;
//  z-index: 600;
//}

.jq-file {
  display: block;
  width: 100%;
  padding: .5em 4rem;
  height: 6.6rem;
  border: 1px solid #b36654;
  background-color: #b36654;
  border-radius: 3.3rem;
  box-shadow: none;
  @media (min-width: 768px) {
    padding: .5rem 4rem;
    height: 4.4rem;
    border-radius: 2.2rem;
    font-size: 2rem;
  }
  &:hover {
    text-decoration: none;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  }
  &:focus {
    text-decoration: none;
    box-shadow: 0 0 0 0 transparent;
  }
  &:active {
    box-shadow: 0 0 0 0 transparent;
    top: 1px;
  }
  input {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
  .jq-file__name {
    font-size: 3rem;
    font-weight: normal;
    display: inline;
    color: white;
    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }
  .jq-file__browse {
    display: none;
  }

  &.jq-file:hover .jq-file__browse {
    color: @red;
  }
  &.jq-file:active .jq-file__browse {

    color: @red;
  }

  &.jq-file.focused .jq-file__name {

  }

  &.jq-file.disabled,
  &.jq-file.disabled .jq-file__name,
  &.jq-file.disabled .jq-file__browse {
    border-color: #CCC;
    background: #F5F5F5;
    box-shadow: none;
    color: #888;
  }
}

.upload-wrap {
  label {
    display: block;
    font-size: 1.6rem;
    font-weight: normal;
  }
  .jq-file {
    padding: .8rem 3rem;
    border-radius: 3px;
    background: #d1d1d1;
  }
  .jq-file__name {
    font-size: 1.2rem;
    font-weight: bold;
  }
}

input[type=checkbox]:not(.unstyled),
input[type=radio]:not(.unstyled) {
  display: none;
}

.jq-checkbox,
.jq-radio {
  vertical-align: -8px;
  width: 24px;
  height: 24px;
  margin: 0 4px 0 0;
  border: 1px solid #C3C3C3;
  background: white;
  //box-shadow: 0 0 1px rgba(0, 0, 0, .05);
  cursor: pointer;
  box-sizing: content-box;
}

.jq-checkbox.focused,
.jq-radio.focused {
  border: 1px solid @orange;
}

.jq-checkbox.disabled,
.jq-radio.disabled {
  opacity: .55;
}

.jq-checkbox {
  border-radius: 3px;
  + span {
    max-width: calc(~"100% - 30px");
    vertical-align: top;
  }
}

.jq-checkbox.checked {
  //border-color: @green;
  box-shadow: inset 0 0 0 2px white;
  background: @orange;
  .jq-checkbox__div {
    width: 9px;
    height: 5px;
    margin: 6px 0 0 6px;
    border-bottom: 3px solid white;
    border-left: 3px solid white;
    transform: rotate(-50deg);
    box-sizing: content-box;
  }
}

.jq-radio {
  border-radius: 50%;
}

.jq-radio.checked {
  background: @orange;
  border-color: @orange;
  .jq-radio__div {
    width: 12px;
    height: 12px;
    margin: 6px 0 0 6px;
    border-radius: 50%;
    background: white;
    //box-shadow: inset 1px 1px 1px rgba(0, 0, 0, .7);
  }
}

.jq-checkbox,
.jq-radio {
  vertical-align: -8px;
  width: 24px;
  height: 24px;
  margin: 0 4px 0 0;
  border: 1px solid #C3C3C3;
  background: white;
  //box-shadow: 0 0 1px rgba(0, 0, 0, .05);
  cursor: pointer;
  box-sizing: content-box;
}

.doc-type-selector {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  .selector-item {
    span {
      display: block;
      width: 235px;
      height: 50px;
      border: 1px solid #a7a7a7;
      background: #f5f5f5;
      position: relative;
      color: #a7a7a7;
      text-transform: uppercase;
      line-height: 50px;
      font-size: 16px;
      font-weight: normal;
      padding-left: 50px;
      &::before {
        display: block;
        content: "";
        width: 18px;
        height: 18px;
        background: #a7a7a7;
        position: absolute;
        top: 15px;
        left: 15px;
      }
    }

    &:not(:first-child) {
      span {
        margin-left: -1px;
      }

    }

    input:checked + span {
      border-color: @orange;
      z-index: 1;
      color: @black_text;
      &::before {
        background: @orange;
      }
    }
  }
}



