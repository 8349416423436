.catalogue-filter-row {
  margin-bottom: 3.5rem;
  fieldset {
    margin: 0;
    padding: 0;
    border: none;
  }
  .filter-btn {
    background: @disable;
    color: @black_text;
    line-height: 1;
    padding: 10px 30px 12px;
    border-radius: 22px;
    margin-right: 1.7rem;
    margin-bottom: 1rem;
    text-decoration: none !important;
    @media (max-width: 767px) {
      font-size: 3rem;
    }
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: @black_text;
    }
    &.hidden {
      background: @red;
      display: inline-block !important;
      color: white;
      &:hover,
      &:focus,
      &:active,
      &:active:focus {
        color: white;
      }
    }
  }
  label, .label {
    display: inline-block;
    font-weight: 300;
    background: @disable;
    color: @black_text;
    line-height: 1;
    padding: 10px 30px 12px;
    border-radius: 22px;
    margin-right: 1.7rem;
    margin-bottom: 1rem;
    position: relative;
    text-decoration: none !important;
    @media (max-width: 767px) {
      font-size: 3rem;
    }
    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      color: @black_text;
    }
    .num {
      position: absolute;
      top: -10px;
      right: -5px;
      background: @red;
      color: white;
      display: block;
      width: 30px;
      height: 30px;
      text-align: center;
      border-radius: 50%;
      font-size: 75%;
      line-height: 30px;
      &:empty {
        display: none;
      }
    }
  }
  input:checked + label {
    background: @red;
    color: white;
    .num {
      background: white;
      color: @black_text;
    }
  }
}

.catalogue-tile {
  background: white;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  transition: box-shadow .25s;

  .title {
    position: absolute;
    bottom: 3rem;
    left: 3rem;
    right: 0;
    margin: 0;
    font-weight: 300;
    font-size: 24px;
    border-left: 1px solid @disable;
    padding-left: 1.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    //max-width: calc(~"100% - 6rem");
    //text-shadow: 0 0 1px white, 0 0 5px white;
    background: fade(white, 80%);
    transition: all .25s;
  }
  .status {
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: 70px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 70px;
    color: white;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 14px;
    > span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      display: block;
      padding-bottom: 20px;
    }
    &.new {
      background: @red;
    }
    &.action {
      background: @orange;
    }
  }

  &:hover {
    .title {
      border-color: @red_text;
    }
    box-shadow: 6.217px 5.035px 40px 0px rgba(196, 183, 174, 0.75);

  }

}

.catalogue-block {
  @media (max-width: 767px) {
    overflow: hidden;
  }
}

.catalogue-block .logo-bg {
  padding-bottom: 5.208333333333333vw;
}
