.actions-block {

}

.actions-tile {
  position: relative;
  max-width: 610px;
  margin-left: auto;
  margin-right: auto;
  .img-wrap {
    border-radius: 5px;


  }
  .text {
    position: absolute;
    left: 19.5rem;
    top: 50%;
    transform: translateY(-50%);
    font-weight: normal;
    font-size: 2.4rem;
    line-height: 1.2;


  }
  &.light {
    .text {
      color: @black_text;
      text-shadow: 0 0 1px white, 0 0 5px white;
    }
  }
  &.dark {
    .text {
      color: white;
      text-shadow: 0 0 1px black, 0 0 5px black;
    }
  }
  .discount {
    background: @red;
    color: white;
    width: 11rem;
    height: 11rem;
    border-radius: 50%;
    position: absolute;
    left: 5rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 1.5rem;
    .border {
      border-radius: 50%;
      border: .2rem dotted white;
      text-align: center;
      white-space: nowrap;



      &::before {
        display: inline-block;
        content: "";
        height: 7.6rem;
        vertical-align: middle;
      }
      .number {
        font-size: 3.5rem;
        font-weight: normal;
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
      }
      .percent {
        font-size: 2rem;
        font-weight: normal;
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
      }
    }

  }
}


