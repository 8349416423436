

.menu-icon-wrapper {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  margin: 23px 15px 23px 23px;
  pointer-events: none;
  transition: 0.1s;

  svg {
    position: absolute;
    top: -33px;
    left: -33px;

    transform: scale(0.1);
    transform-origin: 0 0;
    path {
      stroke: #fff;
      stroke-width: 50px;
      stroke-linecap: round;
      stroke-linejoin: round;
      fill: transparent;
    }
  }
  .nav-trigger {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    pointer-events: auto;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
  }
}

svg:not(:root) {
  overflow: hidden;
}

.mobile-btn-wrap {
  margin: -23px -15px -23px -23px;
  overflow: hidden;
}
