.catalogue-bg {
  min-height: calc(~"100vh - 394px");
  @media (min-width: 1600px) {
    min-height: 1000px;
    background-image: url(../img/catalogue_bg/left_top.png),
    url(../img/catalogue_bg/right_bottom.png),
    url(../img/catalogue_bg/left_bottom.png),
    url(../img/catalogue_bg/right_top.png);
    background-repeat: no-repeat;
    background-position: 0 0,
    100% 100%,
    0 100%,
    100% 0;

  }
}
