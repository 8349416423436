.main-slider {
  padding-bottom: 4rem;
  .slick-list {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 28px 59px 0px rgba(163, 133, 102, 0.3);
  }
  .slick-slide {
    .slide {
      display: block!important;
    }
  }
  .slide {
    position: relative;
    .content-wrap {
      position: absolute;
      padding: 3em 4em;
      top: 0;
      left: 0;
      max-width: 200px;
      box-sizing: content-box;
      width: 100%;
      //text-shadow: 0 0 1px white, 0 0 5px white;
      background: fade(white, 60%);
      border-bottom-right-radius: 10px;
      @media (min-width: 768px) {
        //padding: 5em 6em;
        //top: 5rem;
        //left: 6rem;
        max-width: 400px;
      }
      @media (min-width: 992px) {
        //top: 6.5rem;
        //left: 8rem;
        //padding: 6.5em 8em;
        max-width: 500px;
      }
      .title {
        text-transform: uppercase;
        font-size: 3rem;
        margin-bottom: 2rem;
        line-height: 1.1;
        @media (min-width: 768px) {
          font-size: 4rem;
        }
        @media (min-width: 992px) {
          font-size: 6rem;
        }
      }
      .text {
        font-size: 3rem;
        border-left: 1px solid @red;
        padding: 1rem 4rem;
        line-height: 1.2;
      }
    }
  }
}

.flying-cookies {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(~"100% + 170px");
  overflow: hidden;
  li {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .img-wrap {
      position: absolute;
    }
  }
  @media (max-width: 1200px) {
    li {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        display: none!important;
      }
    }
  }
  @media (max-width: 1400px) {
    li {
      &:nth-child(4) {
        display: none!important;
      }
    }
  }
  @media (max-width: 1500px) {
    li {
      &:nth-child(5),
      &:nth-child(6) {
        display: none!important;
      }
    }
  }
  @media (max-width: 1700px) {
    li {
      &:nth-child(5),
      &:nth-child(6) {
        display: none!important;
      }
    }
  }
}

.main-slider-cookie-bg {
  margin-top: -8rem;
  padding-top: 23rem;
  background-image: url(../img/slider_bg.png);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 1920px 676px;
  @media (max-width: 767px) {
    background-size: 960px auto;
  }
  @media (min-width: 992px) {
    min-height: 676px;
  }
}

.main-slider-block {
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;
}

.offer-wrap {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 767px) {
    font-size: 3rem;
  }
}
