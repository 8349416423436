.product-article-wrap {
  display: block;
  background: white;
  border-radius: 6px;
  padding: 3rem;
  position: relative;
  thead td {
    white-space: nowrap;
  }
  .fotorama__thumb-border {
    border-color: @red;
  }
  .product-title {
    font-size: 4rem;
    font-weight: normal;
  }
  .rich-text-wrap {
    font-size: 2.4rem;
    line-height: 1.2;
  }
}

.product-page {
  overflow: hidden;
}
