.news-tile {
  position: relative;
  .image-col {
    flex-grow: 1000;
    flex-shrink: 1;
  }
  .content-col {
    flex-grow: 1;
    flex-shrink: 1000;
  }
  .img-wrap {
    position: relative;
    img {
      border-radius: 3px;
    }

    .label {
      position: absolute;
      display: block;
      top: 25px;
      left: 0;
      width: 70px;
      background-color: @red;
      color: white;
      font-size: 16px;
      text-align: center;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      line-height: 30px;
    }

  }
  .date-label {
    font-size: 20px;
    font-weight: normal;
    color: @red;
    line-height: 1;
  }
  .news-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 20px;
    font-weight: bold;
    color: @red;
  }
  .rich-text-wrap {
    line-height: 1.2;
    font-size: 20px;

  }
}

.news-block {
  padding-bottom: 4rem;
}

.video-img-wrap {
  position: relative;
  .video-play-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
