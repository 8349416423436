.img-wrap {
  display: block;
  position: relative;
  //z-index: 1;
  overflow: hidden;
  text-align: center;
  img {
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
    display: inline-block;
    vertical-align: middle;
    pointer-events: none;
  }
  &.text-right {
    text-align: right;
  }
  &.text-left {
    text-align: left;
  }
}

.captcha-wrap {
  img {
    height: 52px;
    width: 100%;
  }
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor;
  position: relative;
  pointer-events: none;
}
