.dealer-form-block {
  padding-top: 6rem;
  padding-bottom: 6rem;
  @media (min-width: 768px) {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }
  .logo {
    position: relative;
    max-width: 215px;
    margin: auto;
    overflow: visible;
    &::before {
      display: block;
      content: url(../img/ribbon.png);
      width: 879px;
      height: 400px;
      position: absolute;
      right: 100%;
      top: 0;
    }
  }
  .content {
    max-width: 540px;
    margin: auto;
    @media (max-width: 767px) {
      font-size: 3rem;
    }
  }
  .dealer-form {
    max-width: 700px;
    margin: auto;
  }
}
