html {
  .webkit-scrollbar();



  @media (max-width: 991px) {
    &.popup-open {
      overflow: hidden;
    }
  }
  font-size: 5px;
  @media (min-width: 768px) {
    font-size: 10px;
  }

}

.container {
  @media (min-width: 1366px) {
    width: 1270px;
  }
}

.wide-container {
  @media (min-width: 1600px) {
    margin-left: -17rem;
    margin-right: -17rem;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.page-title {
  font-size: 4.8rem;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.rich-text-wrap {
  font-size: 2rem;
}

.rich-text-wrap,
.mce-content-body {
  font-weight: 300;
  .table-responsive {
    min-width: 100%;
  }
  .table-striped {
    border-color: @disable;
    * {
      border-color: @disable;
    }
    td {
      padding: 1rem;
      text-align: left;
    }
    thead {
      background: @disable;
    }

  }
}

body {
  &:not(.mce-content-body) {
    background-image: url(../img/bg_pattern.jpg);
  }
  &.mce-content-body {
    font-size: 20px;
  }
  @media (min-width: 1920px) {
    max-width: 1920px;
    margin: auto;
  }


}

.logo-bg {
  background-image: url(../img/bg_pattern2.png);
}

.link-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
}

.pdo-page .pagination {
  display: none !important;
}

.svg-defs {
  position: absolute;
  width: 0;
  height: 0;
}

.main-title {
  font-size: 4rem;
  font-weight: 300;
  @media (min-width: 768px) {
    font-size: 5rem;
  }
  @media (min-width: 992px) {
    font-size: 6rem;
  }
  strong {
    font-weight: 500;
  }
}

.red-title {
  color: @red;
}

.border-block {
  padding-left: 3.5rem;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
  border-left: 1px solid white;
  position: relative;
  @media (max-width: 767px) {
    font-size: 2.8rem;
  }
  &::before {
    display: block;
    content: "";
    position: absolute;
    top: 2.5rem;
    left: -3px;
    width: 5px;
    background: white;
    height: 50px;
    border-radius: 2px;
  }
}

.breadcrumb {
  margin-left: -10px !important;
  margin-bottom: 30px;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 300;
  .link-colored(fade(@black_text, 50%), none);
  a {
    display: inline-block;
    //border-bottom: 1px solid fade(@black_text, 50%);
    //transition: border .25s;
    //&:hover {
    //  border-color: transparent;
    //}
  }
  li {
    display: inline-block;
    padding-left: 10px !important;
    padding-right: 10px;
    text-indent: initial !important;
  }
}

.page-title-block {
  .main-title {
    margin-bottom: 4rem;
  }
}

#pdopage .pagination {
  display: none !important;
}

.fotorama {
  > .img-wrap + .img-wrap {
    display: none;
  }
}

.contacts-bg {
  @media (min-width: 992px) {
    background-image: url(../img/contacts_bg/bottom.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    padding-bottom: 275px;
  }
  @media (min-width: 1600px) {
    min-height: 800px;
    background-image: url(../img/contacts_bg/left_top.png), url(../img/contacts_bg/right_top.png), url(../img/contacts_bg/bottom.png);
    background-repeat: no-repeat;
    background-position: 0 0, 100% 0, center bottom;
  }

}

.contacts-page {

  .columns {
    @media (min-width: 768px) {
      column-count: 2;
      column-gap: 30px;
    }
    > * {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid-column;
    }
  }
  .rich-text-wrap {
    font-size: 3rem;
    @media (min-width: 768px) {
      font-size: 2.4rem;
    }
  }
}

.common-bg {
  @media (min-width: 1600px) {
    min-height: 1000px;
    background-image: url(../img/common_bg/left-top.png), url(../img/common_bg/right-top.png);
    background-repeat: no-repeat;
    background-position: 0 0, 100% 0;
  }
  .commom-content {
    background-color: white;
    border-radius: 10px;
    padding: 3rem;
  }
}

.mfp-img {
  background: white;
}


.captcha-div {
  text-align: center;
  > div {
    margin: 0 auto;
  }

}
