.search-block {
  .total-wrap {
    font-weight: bold;
    font-size: 24px;
  }
}

.search-form-wrap {
  position: relative;
  input {
    font-weight: bold;
    font-size: 24px;
    background: white;
    border: 1px solid @disable;
    border-radius: 10000px;
    display: block;
    width: 100%;
    padding: .5rem 23rem .5rem 3rem;

  }
  button {
    width: 20rem;
    position: absolute;
    top: 0;
    right: 0;
    background: @red;
    color: white;
    border-radius: 10000px;
    border: 1px solid @red;
    text-transform: uppercase;
    padding: .7rem 2rem;
    font-size: 20px;
  }
}

.search-tile {
  border-radius: 9px;
  transition: all .25s;
  padding: 1.5rem 2.5rem;
  position: relative;
  &:hover {
    box-shadow: 0px 7px 40px 0px rgba(60, 48, 49, 0.18);
    background: white;
  }
  .search-tile-title {
    font-size: 4rem;
    font-weight: normal;
    margin: 0 0 1rem;
  }
  .parent {
    font-size: 2.4rem;
    font-weight: 300;
    margin-bottom: 1rem;
  }
  .published {
    font-size: 18px;
    font-weight: 300;
    color: #737373;
  }
  .crumbs {
    font-size: 18px;
    font-weight: 300;
    color: #737373;
    .link-colored(#737373, none);
    .breadcrumb {
      display: inline-block;
      margin-bottom: 0;
      margin-top: 0;
      font-size: inherit;
    }
  }
}
