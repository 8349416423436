.production-block {
  position: relative;
  overflow: hidden;
  margin-top: -2.083333333333333vw;
  margin-bottom: -2.083333333333333vw;
  @media (min-width: 768px) {
    padding-top: 6.25vw;
    margin-top: -6.25vw;
    margin-bottom: -8.333333333333333vw;
    padding-bottom: 6.25vw;
  }
  @media (min-width: 1920px) {
    padding-top: 120px;
    margin-top: -120px;
    margin-bottom: -160px;
    padding-bottom: 120px;
  }
  image {
    mask: url(#productionMask)
  }
  .production-bars {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    margin-left: -15.625vw;
    width: 67.39583333333333vw;
    height: 63.38541666666667vw;
    @media (max-width: 767px) {
      display: none;
    }
    @media (min-width: 1920px) {
      margin-left: -300px;
      width: 1294px;
      height: 1217px;
    }
  }
  .production-cookies {
    position: absolute;
    top: 50%;
    left: 0;
    width: 42.08333333333333vw;
    height: 33.54166666666667vw;
    margin-top: -5.208333333333333vw;
    @media (max-width: 1200px) {
      display: none;
    }

    @media (min-width: 1920px) {
      width: 808px;
      height: 644px;
      margin-top: -100px;
    }
  }
  .rich-text-wrap {
    text-shadow: 0 0 1px black, 0 0 5px black;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 5.208333333333333vw;
    @media (min-width: 768px) {
      padding-top: calc(5.729166666666667vw + 5.208333333333333vw);
    }
    @media (min-width: 1920px) {
      padding-top: 210px;
    }
    color: white;
    transform: scale(1) translateZ(0);
    font-size: 2rem;
    .main-title {
      margin-bottom: 3rem;
    }
    .content {
      max-width: 100%;
      @media (min-width: 768px) {
        max-width: 45%;
      }
    }

  }
}
