.partners-block {
  color: @black_text;
  font-size: 24px;
  padding-top: 6rem;
  @media (min-width: 768px) {
    padding-top: 12rem;
  }
  .main-title {
    margin-bottom: 3rem;
  }
  .border-block {
    border-color: @black_text;
    &::before {
      background: @black_text;
    }
    @media (max-width: 767px) {
      font-size: 3rem;
    }


  }
}

.partners-slider {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  .slide {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .partner-logo {


    .img-wrap {
      border-radius: 50%;
      overflow: hidden;
      width: 185px;
      height: 185px;
      margin: auto;
      background: white;
      padding: 10px;
      &::before {
        display: inline-block;
        content: "";
        height: 165px;
        vertical-align: middle;
      }
    }

  }
}
